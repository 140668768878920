export type ShadowType = 'rest' | 'elevate' | 'elevateCast' | 'pressed';

export type Shadows = {
  [type in ShadowType]: string;
};

export const shadows: Shadows = {
  rest: `0px 1px 3px rgba(24, 32, 38, 0.09), 0px 2px 2px rgba(24, 32, 38, 0.06),
    0px 3px 2px rgba(24, 32, 38, 0.07)`,
  elevate: `0px 8px 10px rgba(24, 32, 38, 0.09), 0px 6px 30px rgba(24, 32, 38, 0.11), 0px 16px 24px rgba(24, 32, 38, 0.13)`,
  elevateCast: `0px 3px 9px rgba(24, 32, 38, 0.18)`,
  pressed: `0px 1px 3px rgba(24, 32, 38, 0.18), 0px 2px 2px rgba(24, 32, 38, 0.11), 0px 0px 2px rgba(24, 32, 38, 0.13)`,
};
