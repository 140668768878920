import styled from 'styled-components';
import { typography } from '../ThemeProvider/ThemeProvider';
import { InputSize } from './InputBase';

export const InputStyledBase = styled.input.attrs<{ htmlSize?: number }>(({ htmlSize, size }) => ({
  size: htmlSize,
  controlSize: size,
}))<{ size?: InputSize; controlSize?: 'small' | 'normal' }>`
  ${typography('cell')}
  appearance: none;
  background: ${({ theme }) => theme.colorsNew.gray.white};
  border: 1px solid ${({ theme }) => theme.colorsNew.gray.grayMedium};
  border-radius: 4px;
  outline: none;
  margin: 0;
  padding: ${({ controlSize }) => (controlSize === 'small' ? '6px 11px' : '11px')};
  padding-left: ${({ type }) => (type === 'search' ? '36px' : null)};
  width: 100%;
  resize: none;
  text-align: inherit;
  transition: border 200ms, box-shadow 200ms;

  :disabled {
    opacity: 0.36;
  }

  :focus {
    border: 1px solid ${({ theme }) => theme.colorsNew.increase.light50};
    color: ${({ theme }) => theme.colorsNew.gray.black};
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colorsNew.gray.grayBold};
  }

  :focus {
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colorsNew.increase.light};
    outline: 0;

    &::-moz-focus-inner {
      border: 0;
    }
  }

  :not(:disabled):hover {
    border: 1px solid ${({ theme }) => theme.colorsNew.gray.black};
  }
`;
