import React from 'react';
import styled from 'styled-components';
import { InputStyledBase } from './InputStyledBase';
import { InputBaseProps, InputBase } from './InputBase';

export type InputNumberProps = {
  prefix?: string;
  step?: string;
  width?: string;
} & InputBaseProps;

const InputContainer = styled.div`
  border-radius: 4px;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  .prefix {
    align-items: center;
    display: flex;
    font-size: 13px;
    height: 100%;
    line-height: 14px;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    width: 20px;
  }
`;

type InputProps = {
  prefix?: string;
};

const InputStyled = styled(InputStyledBase)<InputProps>`
  padding-right: 6px;
  padding-left: ${({ prefix }) => (prefix ? '20px' : '12px')};
`;

const InputNumber = ({ prefix, ...rest }: InputNumberProps) => {
  return (
    <InputBase {...rest}>
      {(inputProps: typeof InputStyledBase.arguments) => (
        <>
          <InputContainer>
            {prefix && <span className="prefix">{prefix}</span>}
            <InputStyled {...inputProps} type="number" />
          </InputContainer>
        </>
      )}
    </InputBase>
  );
};

export { InputNumber };
