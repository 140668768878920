import React, { AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';
import { LinkExternalIcon } from '@increasecard/icons';

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  variant?: 'normal' | 'alert';
}

export const BasicLink = styled.a<LinkProps>`
  border: none;
  background: transparent;
  font: inherit;
  color: ${({ theme, variant }) =>
    variant === 'alert'
      ? theme.colorsNew.coralAlert.regular
      : theme.colorsNew.primaryAction.regular};
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
  border-radius: 4px;
  transition: all 200ms;

  :focus {
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colorsNew.increase.light};
    outline: 0;

    &::-moz-focus-inner {
      border: 0;
    }
  }

  :disabled {
    opacity: 0.4;
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &:hover:active {
      text-decoration: underline;
    }

    &:hover,
    &:active {
      color: ${({ theme, variant }) =>
        variant === 'alert' ? theme.colorsNew.coralAlert.dark : theme.colorsNew.primaryAction.dark};
    }
  }
`;

export const Link = (props: typeof BasicLink.arguments) => (
  <BasicLink {...props}>
    {props.children}
    {props.target === '_blank' && (
      // 18px / 13px => 1.385em
      <LinkExternalIcon height="1.385em" style={{ marginLeft: '1px' }} width="1.385em" />
    )}
  </BasicLink>
);
