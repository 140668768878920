import React from 'react';
import styled from 'styled-components';
import { typography } from '../ThemeProvider/ThemeProvider';
import { ArrowLeftIcon, ArrowRightIcon } from '@increasecard/icons';

const Wrapper = styled.div`
  display: flex;
`;

const PageButton = styled.button<{ active?: boolean }>`
  ${typography('cell')}
  &:not(:disabled) {
    cursor: pointer;
  }
  transition: border 200ms, color 200ms, box-shadow 200ms;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  min-width: max-content;
  height: 30px;
  padding: 0 1px;
  color: ${({ theme }) => theme.colorsNew.gray.blackLight};
  border-radius: 4px;
  text-align: center;
  background-color: ${({ active, theme }) =>
    active ? theme.colorsNew.increase.light : 'transparent'};
  border: 1px solid
    ${({ active, theme }) => (active ? theme.colorsNew.gray.subtleBorder : 'transparent')};

  :not(:last-child) {
    margin-right: 3px;
  }

  :focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colorsNew.increase.light};
    border: 1px solid ${({ theme }) => theme.colorsNew.increase.light50};
    outline: 0;

    &::-moz-focus-inner {
      border: 0;
    }
  }

  :not(:disabled):hover {
    border: 1px solid ${({ theme }) => theme.colorsNew.gray.subtleBorder};
    color: ${({ theme }) => theme.colorsNew.increase.regular};
  }

  :disabled > svg {
    opacity: 0.5;
  }
`;

const EllipsisButton = styled(PageButton).attrs({ as: 'span', children: '...' })`
  cursor: default;
  color: inherit;

  &:not(:disabled):hover {
    cursor: default;
    border: 1px solid transparent;
    color: inherit;
  }
`;

const range = (start: number, end: number, step = 1) => {
  const range = [];
  for (let i = start; i < end; i += step) {
    range.push(i);
  }
  return range;
};

interface PaginationProps extends React.HTMLAttributes<HTMLDivElement> {
  currentPage: number;
  totalPages: number;
  onSelectPage: (page: number) => void;
}

export function Pagination({ currentPage, totalPages, onSelectPage, ...rest }: PaginationProps) {
  let from = currentPage > 2 ? currentPage - 2 : 1;
  const to = Math.min(from + 4, totalPages);
  from = Math.max(to - 4, 1);
  const pagenums = range(from, to + 1);

  return (
    <Wrapper {...rest}>
      <PageButton
        aria-label="Anterior"
        disabled={currentPage === 1}
        onClick={() => onSelectPage(currentPage - 1)}
      >
        <ArrowLeftIcon height="24" width="24" />
      </PageButton>
      {!pagenums.includes(1) && (
        <>
          <PageButton active={false} disabled={false} onClick={() => onSelectPage(1)}>
            {1}
          </PageButton>
          {!pagenums.includes(2) && <EllipsisButton />}
        </>
      )}
      {pagenums.map((n, i) => {
        return (
          <PageButton
            active={n === currentPage}
            disabled={currentPage === n}
            key={i}
            onClick={() => onSelectPage(n)}
          >
            {n}
          </PageButton>
        );
      })}
      {!pagenums.includes(totalPages) && (
        <>
          {!pagenums.includes(totalPages - 1) && <EllipsisButton />}
          <PageButton active={false} disabled={false} onClick={() => onSelectPage(totalPages)}>
            {totalPages}
          </PageButton>
        </>
      )}
      <PageButton
        aria-label="Siguiente"
        disabled={currentPage === totalPages}
        onClick={() => onSelectPage(currentPage + 1)}
      >
        <ArrowRightIcon height="24" width="24" />
      </PageButton>
    </Wrapper>
  );
}
