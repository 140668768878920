import React from 'react';
import Calendar from 'react-calendar';
import styled from 'styled-components';
import { typography } from '../ThemeProvider/ThemeProvider';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import { ArrowRightIcon, ArrowLeftIcon } from '@increasecard/icons';

export const Datepicker = styled(Calendar).attrs({
  prev2Label: null,
  next2Label: null,
  prevLabel: <ArrowLeftIcon height="24" width="24" />,
  nextLabel: <ArrowRightIcon height="24" width="24" />,
  calendarType: 'US',
  formatShortWeekday: (_locale, date) => format(date, 'EEEEE', { locale: es }).toLocaleUpperCase(),
  navigationLabel: ({ date }) => format(date, 'MMMM Y', { locale: es }),
})`
  background: ${({ theme }) => theme.colorsNew.gray.white};
  border: 1px solid ${({ theme }) => theme.colorsNew.gray.grayMedium};
  border-radius: 4px;
  padding: 12px 8px;
  width: 300px;
  box-shadow: ${({ theme }) => theme.shadows.rest};
  button {
    background: transparent;
    border: none;
  }

  .react-calendar__navigation__label {
    ${typography('body')}
    text-transform: capitalize;
  }

  .react-calendar__navigation {
    margin-bottom: 16px;
    justify-content: flex-start;
  }

  .react-calendar__navigation__arrow {
    display: inline-flex;
    align-items: center;
  }

  .react-calendar__year-view__months__month,
  .react-calendar__decade-view__years__year,
  .react-calendar__century-view__decades__decade {
    ${typography('cell')}
    text-transform: capitalize;
    padding: 8px 0;

    > abbr {
      padding: 4px;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    ${typography('body')}
    display: inline-flex;
    justify-content: center;
    align-items: center;
    > abbr {
      text-decoration: none;
    }
  }

  .react-calendar__tile.react-calendar__month-view__days__day {
    ${typography('cell')}
    height: 27px;
    width: 27px;
    padding: 0 6px;
    > abbr {
      height: 100%;
      width: 100%;
      border-radius: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .react-calendar__tile--rangeStart ~ .react-calendar__tile--active > abbr {
    background: ${({ theme }) => theme.colorsNew.increase.light};
    color: ${({ theme }) => theme.colorsNew.gray.black};
  }

  .react-calendar__tile--active > abbr,
  .react-calendar__tile--rangeStart.react-calendar__tile--active > abbr,
  .react-calendar__tile--rangeEnd.react-calendar__tile--active > abbr,
  .react-calendar__tile--rangeEnd.react-calendar__tile--hasActive > abbr {
    background: ${({ theme }) => theme.colorsNew.increase.regular};
    color: ${({ theme }) => theme.colorsNew.gray.white};
  }

  .react-calendar__month-view__days__day.react-calendar__tile--active.react-calendar__tile--now
    > abbr {
    border: 1px solid transparent;
    border-radius: 100%;
  }

  .react-calendar__tile--now > abbr,
  .react-calendar__decade-view__years__year.react-calendar__tile--now {
    border: 1px solid ${({ theme }) => theme.colorsNew.increase.regular};
    border-radius: 9px;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.36;
  }
`;
