import styled, { css } from 'styled-components';
import { typography } from '../ThemeProvider/ThemeProvider';
import { Link } from '../Link/Link';

export const VerticalNavigation = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px 0;
  height: calc(100% - 32px);
`;

interface VerticalNavigationElementProps {
  active: boolean;
  disabled?: boolean;
}

export const VerticalNavigationElement = styled(Link)<VerticalNavigationElementProps>`
  ${typography('cell')}
  color: ${({ theme }) => theme.colorsNew.gray.black};
  ${({ active }) =>
    active
      ? css`
          color: ${({ theme }) => theme.colorsNew.primaryAction.regular};
        `
      : null};
  height: 26px;
  cursor: pointer;
  border: 0;
  border-radius: 0;
  background: transparent;
  border-left: 3px solid ${({ active, theme }) =>
    active ? theme.colorsNew.primaryAction.regular : 'transparent'};
  padding-left: 11px;

  &, :hover {
    text-decoration: none;
  }

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
          opacity: 0.36;
        `
      : css`
          :hover {
            color: ${({ theme }) => theme.colorsNew.primaryAction.regular};
          }
        `}

  ${({ active }) =>
    active &&
    css`
      cursor: default;
    `}

  &:not(:first-child) {
    margin-top: 16px;
  }
`;
