import React from 'react';
import { InputBase, InputBaseProps } from './InputBase';

export type InputTextProps = InputBaseProps;

const InputText = (props: InputTextProps) => {
  return <InputBase {...props} type="text" />;
};

export { InputText };
