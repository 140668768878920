import React from 'react';
import { ErrorTrackingContext } from '../contexts/ErrorTrackingContext';
import { ErrorScreen } from './ErrorScreen';

export interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static contextType = ErrorTrackingContext;
  context!: React.ContextType<typeof ErrorTrackingContext>;
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    const rollbar = this.context;
    if (rollbar === null) return;

    rollbar.error(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return <ErrorScreen />;
    }
    return this.props.children;
  }
}
