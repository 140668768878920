import styled, { css } from 'styled-components';
import { typography } from '../../ThemeProvider/ThemeProvider';

type TabProps = {
  active?: boolean;
};

export const Tab = styled.button<TabProps>`
  box-sizing: border-box;
  background: ${({ theme }) => theme.colorsNew.gray.black};
  color: ${({ active, theme }) =>
    active ? theme.colorsNew.gray.white : theme.colorsNew.gray.grayBold};
  border: none;
  display: flex;
  align-items: center;
  ${typography('label')};
  font-weight: 500;
  height: 100%;
  text-transform: uppercase;
  ${({ active, theme }) =>
    active
      ? css`
          border-bottom: 6px solid ${theme.colorsNew.gray.white};
          border-top: 6px solid transparent;
        `
      : ''}

  &:not(:disabled) {
    ${({ active }) => !active && 'cursor: pointer;'}
  }
  &:hover:not(:disabled) {
    color: ${({ theme }) => theme.colorsNew.gray.white};
  }

  * + & {
    margin-left: 40px;
  }
`;
