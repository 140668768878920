import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Tab } from './TabGroup/Tab';
import { TabGroup, TabGroupWrapper } from './TabGroup/TabGroup';
import { ApplicationHeader } from './ApplicationHeader';
import { ApplicationHeaderMobile, ApplicationHeaderMobileProps } from './ApplicationHeaderMobile';

export interface ApplicationLayoutProps
  extends Omit<ApplicationHeaderMobileProps, 'onMenuOpen' | 'isMenuOpen'> {
  leftContext?: React.ReactNode;
  rightContext?: React.ReactNode;
}

const ApplicationLayoutWrapper = styled.div<{ isMobileMenuOpen: boolean }>`
  position: relative;
  padding-top: 72px;

  ${({ isMobileMenuOpen }) =>
    isMobileMenuOpen
      ? css`
          height: 100vh;
          overflow: hidden;
        `
      : null}

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    overflow-x: hidden;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: 56px;
  }
`;

const ApplicationHeaderOnlyDesktop = styled(ApplicationHeader)`
  position: absolute;
  top: 0;
  width: 100%;
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`;

const ApplicationHeaderOnlyMobile = styled(ApplicationHeaderMobile)`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

const TabGroupOnlyDesktop = styled(TabGroupWrapper)`
  display: none;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
  }
`;

const ContentWrapper = styled.div`
  min-width: 100%;
  z-index: 0;
  position: relative;
`;

export function ApplicationLayout({
  children,
  tabs,
  leftContext,
  rightContext,
  ...rest
}: ApplicationLayoutProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleIsMenuOpen = () => setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  return (
    <ApplicationLayoutWrapper isMobileMenuOpen={isMenuOpen}>
      {tabs !== null && (
        <TabGroupOnlyDesktop>
          {leftContext}
          <TabGroup>
            {tabs.map(({ active, name, ...rest }) => (
              <Tab active={active} key={name} {...rest}>
                {name}
              </Tab>
            ))}
          </TabGroup>
          {rightContext}
        </TabGroupOnlyDesktop>
      )}
      <ApplicationHeaderOnlyDesktop {...rest} />
      <ApplicationHeaderOnlyMobile
        isMenuOpen={isMenuOpen}
        onMenuOpen={toggleIsMenuOpen}
        tabs={tabs}
        {...rest}
      />
      <ContentWrapper>{children}</ContentWrapper>
    </ApplicationLayoutWrapper>
  );
}
