import React from 'react';
import styled, { css } from 'styled-components';

import { H3 } from '../typography/typography';
import { MenuLink } from './MenuLink';
import { MobileContextualMenu } from './MobileContextualMenu';
import { IncreaseLogo, MenuIcon } from '@increasecard/icons';
import { ApplicationHeaderProps } from './ApplicationHeader';
import { TabDefinition } from './types';

export interface ApplicationHeaderMobileProps extends ApplicationHeaderProps {
  tabs: Array<TabDefinition> | null;
  onMenuOpen: () => void;
  isMenuOpen: boolean;
}

export const Backdrop = styled.div<{
  isMenuOpen: boolean;
}>`
  ${({ isMenuOpen }) =>
    !isMenuOpen &&
    css`
      display: none;
    `}
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(24, 32, 38, 0.7);
  z-index: 1;
`;
export const ApplicationHeaderMobileContainer = styled.div<{ isMenuOpen: boolean }>`
  position: relative;
  height: 100vh;

  ${({ isMenuOpen }) =>
    isMenuOpen
      ? css`
          overflow: hidden;
        `
      : null}
`;
export const ApplicationHeaderMobileWrapper = styled.div`
  margin: 8px 8px 0 8px;
  padding: 8px;
  height: 56px;
  width: calc(100% - 16px);
  background: ${({ theme }) => theme.colorsNew.gray.white};
  display: flex;
  position: fixed;
  z-index: 1;
  top: 0;
  align-items: center;
  justify-content: space-between;
  border-radius: 9px;
  box-shadow: ${({ theme }) => theme.shadows.rest};

  .left > svg {
    margin-right: 8px;
  }

  .menu {
    background: transparent;
    height: 24px;
    width: 24px;
    border: none;
    background-color: ${({ theme }) => theme.colorsNew.gray.white};
    padding: 0;
  }

  .left {
    display: flex;
    align-items: center;
  }
`;
export const MobileDrawer = styled.div<{
  isMenuOpen: boolean;
}>`
  width: calc(100vw - 58px);
  height: 100vh;
  z-index: 1;
  background: ${({ theme }) => theme.colorsNew.gray.white};
  position: absolute;
  top: 0;
  right: -100%;
  transition: right 250ms;
  overflow: hidden;

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      right: 0;
    `}
`;

const UserMenu = styled.div`
  height: 72px;
  padding: 16px;
  background: ${({ theme }) => theme.colorsNew.gray.grayLight};
  width: 100%;
`;

/* eslint-disable @typescript-eslint/no-unused-vars */
export function ApplicationHeaderMobile({
  products,
  isMenuOpen,
  userName,
  onMenuOpen,
  tabs,
  onProductAcquire,
  ...rest
}: ApplicationHeaderMobileProps) {
  const product = products.all.find(({ name }) => name === products.current);
  const productName = product ? product.display_name : 'Increase';
  return (
    <nav {...rest}>
      <ApplicationHeaderMobileWrapper>
        <div className="left">
          <IncreaseLogo />
          <H3>{productName}</H3>
        </div>
        {tabs !== null && (
          <button aria-label="abrir menú" className="menu" onClick={onMenuOpen}>
            <MenuIcon height="24" width="24" />
          </button>
        )}
      </ApplicationHeaderMobileWrapper>
      <Backdrop isMenuOpen={isMenuOpen} onClick={onMenuOpen} />
      {tabs !== null && (
        <MobileDrawer isMenuOpen={isMenuOpen}>
          <UserMenu>
            <H3>{userName}</H3>
            <MenuLink href="#" style={{ color: 'black' }}>
              Ajustes de cuenta
            </MenuLink>
          </UserMenu>
          <MobileContextualMenu onElementClicked={onMenuOpen} tabs={tabs} />
          {/* TODO(santi698): Add platform and notifications pages here */}
          {/* TODO(santi698): Add Carousel slider here */}
        </MobileDrawer>
      )}
    </nav>
  );
}
