import React, { useState, useRef } from 'react';
import styled from 'styled-components';

import MainNavMenu from './MainNavMenu';
import MenuProfile from './MenuProfile';
import { Dict } from '../Type/Dict';
import { Product, SecondLevelLink, SidebarLink, ProfileLink } from './types';
import { typography } from '../ThemeProvider/ThemeProvider';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { IncreaseLogo, ArrowDropdownUp, ArrowDropdownDown } from '@increasecard/icons';

const ApplicationHeaderContainer = styled.div`
  position: relative;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const ApplicationHeaderWrapper = styled.div`
  background: ${({ theme }) => theme.colorsNew.gray.white};
  position: relative;
  z-index: 2;
  border-bottom: 1px solid ${({ theme }) => theme.colorsNew.gray.grayMedium};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 26px;
`;

const ApplicationHeaderContent = styled.nav`
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LogoWrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.colorsNew.gray.black};
  :focus {
    outline: none;
  }
  .logo {
    height: 25px;
    width: 25px;
  }
  .brandName {
    ${typography('H3')}
    font-weight: bold;
    margin: 0 8px 0 8px;
  }

  .drawerToggle {
    display: flex;
    align-items: center;
    color: ${({ isOpen, theme }) => (isOpen ? theme.colorsNew.primaryAction.regular : null)};
  }

  .drawerToggle path {
    fill: ${({ isOpen, theme }) => (isOpen ? theme.colorsNew.primaryAction.regular : null)};
  }

  .drawerToggle:hover {
    color: ${({ theme }) => theme.colorsNew.primaryAction.regular};
  }

  .drawerToggle:hover path {
    fill: ${({ theme }) => theme.colorsNew.primaryAction.regular};
  }
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
  .alerts {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid ${({ theme }) => theme.colorsNew.gray.grayMedium};
    color: ${({ theme }) => theme.colorsNew.gray.grayMedium};
  }
`;

export interface ApplicationHeaderProps extends React.BaseHTMLAttributes<HTMLDivElement> {
  country: string;
  onProductAcquire: (product: Product) => Promise<void>;
  products: {
    all: Product[];
    current: string;
    active: Dict<string[]>;
  };
  userName: string;
  urls: { platform: string; platformApi: string; auth: string; landing: string };
  links: {
    secondLevel: SecondLevelLink[];
    sidebar: SidebarLink[];
    profile: ProfileLink[];
  };
}

export function ApplicationHeader({
  products,
  links,
  urls,
  country,
  onProductAcquire,
  userName,
  ...rest
}: ApplicationHeaderProps) {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    if (isOpen) {
      setOpen(!isOpen);
    }
  });

  const product = products.all.find(({ name }) => name === products.current);
  const productName = product ? product.display_name : 'Increase';
  const handleClick = () => setOpen((open) => !open);

  const sidebarLinks = links.sidebar.concat([
    {
      label: 'Más Productos',
      url: `${urls.platform}/`,
      blank: false,
    },
    {
      label: 'Ajustes',
      url: `${urls.platform}/settings/`,
      blank: false,
    },
  ]);

  return (
    <ApplicationHeaderContainer ref={ref} {...rest}>
      <MainNavMenu
        country={country}
        onProductAcquire={onProductAcquire}
        products={products}
        secondLevel={links.secondLevel}
        sidebar={sidebarLinks}
        visible={isOpen}
      />
      <ApplicationHeaderWrapper>
        <ApplicationHeaderContent>
          <div className="logo">
            <LogoWrapper isOpen={isOpen} onClick={handleClick}>
              <IncreaseLogo />
              <div className="drawerToggle">
                <span className="brandName">{productName}</span>
                {isOpen ? <ArrowDropdownUp /> : <ArrowDropdownDown />}
              </div>
            </LogoWrapper>
          </div>
          <RightContent>
            <div className="account">
              <MenuProfile profileLinks={links.profile} urls={urls} userName={userName} />
            </div>
          </RightContent>
        </ApplicationHeaderContent>
      </ApplicationHeaderWrapper>
    </ApplicationHeaderContainer>
  );
}
