import React from 'react';

export type CurrencyProps = {
  currency: string;
  language?: string;
  value: number;
};

export function Currency({ value, currency, language = navigator.language }: CurrencyProps) {
  if (isNaN(value)) {
    return null;
  }
  const options = {
    minimumFractionDigits: 2,
    style: 'currency',
    currency,
  };
  const numberFormatter = new Intl.NumberFormat(language, options);
  return <React.Fragment>{numberFormatter.format(value)}</React.Fragment>;
}
