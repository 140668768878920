import React from 'react';

import styled, { css } from 'styled-components';
import { H2 } from '../typography/typography';
import { typography } from '../ThemeProvider/ThemeProvider';

type ButtonDrawerProps = {
  alt?: string;
  className?: string;
  href: string;
  displayName: string;
  description: string;
  selected: boolean;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

function ButtonDrawer({ className, href, displayName, description, ...rest }: ButtonDrawerProps) {
  return (
    <a className={className} href={href} {...rest}>
      <H2>{displayName}</H2>
      {description}
    </a>
  );
}

const StyledButtonDrawer = styled(ButtonDrawer)`
  ${typography('cell')}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 6px solid ${({ theme }) => theme.colorsNew.product.light};
  transition: 0.25s;
  height: 100%;

  a {
    color: inherit;
  }

  .drawer-image {
    margin-top: 2.5rem;
  }
  p {
    margin: 15px 0 0;
    font-size: 13px;
    color: #38f;
    text-decoration: underline;
  }
  &:hover {
    border-bottom-color: ${({ theme }) => theme.colorsNew.primaryAction.regular};
    color: ${({ theme }) => theme.colorsNew.primaryAction.dark};
  }

  &:hover h2 {
    color: ${({ theme }) => theme.colorsNew.primaryAction.dark};
  }

  img {
    height: 34px;
    width: auto;
  }

  ${({ selected }) =>
    selected &&
    css`
      border-bottom-color: ${({ theme }) => theme.colorsNew.primaryAction.regular};
      pointer-events: none;
      background: ${({ theme }) => theme.colorsNew.gray.white};
      box-shadow: ${({ theme }) => theme.shadows.elevateCast};
    `}
`;

export default StyledButtonDrawer;
