import { useEffect, RefObject } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref.
 *
 * Source: https://stackoverflow.com/a/42234988
 */
export function useOnClickOutside(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: RefObject<any>,
  onClickOutside: (evt: Event) => void
) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event: Event): void {
    if (ref.current && !ref.current.contains(event.target)) {
      onClickOutside(event);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}
