import React from 'react';
import styled from 'styled-components';
import { typography } from '../ThemeProvider/ThemeProvider';
import { InputSize, InputBase } from '../Inputs';
import { ArrowDropdownDown } from '@increasecard/icons';

const SelectInput = styled.select<{ size?: InputSize }>`
  ${typography('cell')}
  height: ${({ size }) => (size === 'small' ? '30px' : '40px')};
  padding: ${({ size }) => (size === 'small' ? '6px 49px 6px 11px' : '11px 49px 11px 11px')};
  border: 1px solid ${({ theme }) => theme.colorsNew.gray.grayMedium};
  border-radius: 4px;
  vertical-align: middle;
  appearance: none;
  background: transparent;
  margin: 0;
  outline: 0;
  text-align: inherit;
  position: relative;
  width: 100%;
  transition: all 200ms;

  :focus {
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colorsNew.increase.light};
    outline: 0;

    &::-moz-focus-inner {
      border: 0;
    }
  }

  &:active,
  &:focus {
    border: 1px solid ${({ theme }) => theme.colorsNew.increase.light50};
  }
`;

export type SelectProps = {
  label: string;
  labelHidden?: boolean;
  id: string;
  containerStyle?: React.CSSProperties;
  containerClassName?: string;
  selectStyle?: React.CSSProperties;
  selectClassName?: string;
  inline?: boolean;
} & typeof SelectInput.arguments;

const ArrowWrapper = styled.div<Pick<SelectProps, 'size'>>`
  position: absolute;
  right: 3px;
  bottom: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ size }) => (size === 'small' ? '24px' : '34px')};
  width: ${({ size }) => (size === 'small' ? '24px' : '34px')};
  border-left: 1px solid ${({ theme }) => theme.colorsNew.gray.grayMedium};
  pointer-events: none;

  select:focus-within ~ &,
  select:active ~ & {
    background-color: ${({ theme }) => theme.colorsNew.gray.grayLight};
    border-left: 1px solid transparent;
    border-radius: 4px;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export function Select({ children, ...props }: SelectProps) {
  return (
    <InputBase {...props}>
      {(inputProps: typeof SelectInput.arguments) => (
        <InputWrapper>
          <SelectInput {...inputProps}>{children}</SelectInput>
          <ArrowWrapper size={inputProps.size}>
            <ArrowDropdownDown />
          </ArrowWrapper>
        </InputWrapper>
      )}
    </InputBase>
  );
}

type SelectOptGroupProps = React.DetailedHTMLProps<
  React.OptgroupHTMLAttributes<HTMLOptGroupElement>,
  HTMLOptGroupElement
>;

export function SelectOptGroup(props: SelectOptGroupProps) {
  return <optgroup {...props} />;
}

type SelectOptionProps = React.DetailedHTMLProps<
  React.OptionHTMLAttributes<HTMLOptionElement>,
  HTMLOptionElement
>;

export function SelectOption(props: SelectOptionProps) {
  return <option {...props} />;
}
