import React from 'react';
import { Label } from './Label';
import { LabelError } from './LabelError';
import { InputWrapper } from './InputWrapper';
import { InputStyledBase } from './InputStyledBase';

export type InputSize = 'small' | 'normal';

export type InputBaseProps = {
  children?: (inputProps: typeof InputStyledBase.arguments) => React.ReactNode;
  /** The className for the outer wrapper */
  className?: string;
  /** The styles for the outer wrapper */
  style?: React.CSSProperties;
  disabled?: boolean;
  labelHidden?: boolean;
  id: string;
  label: string;
  width?: string;
  errorMessage?: string;
  inputClassName?: string;
  inputStyle?: React.CSSProperties;
  inline?: boolean;
  size?: InputSize;
} & typeof InputStyledBase.arguments;

export function InputBase({
  className,
  children = (inputProps: typeof InputStyledBase.arguments) => <InputStyledBase {...inputProps} />,
  disabled,
  id,
  label,
  labelHidden,
  type = 'text',
  value,
  style,
  width,
  errorMessage,
  inputClassName,
  inputStyle,
  inline,
  required,
  size,
  ...rest
}: InputBaseProps) {
  return (
    <InputWrapper
      className={className}
      error={errorMessage ? true : false}
      inline={inline}
      style={style}
      width={width}
    >
      {!labelHidden && (
        <Label htmlFor={id} inline={inline} required={required}>
          {label}
        </Label>
      )}
      {children({
        ...rest,
        'aria-label': labelHidden ? label : undefined,
        className: inputClassName,
        disabled: disabled,
        id: id,
        name: id,
        required: required,
        size: size,
        style: inputStyle,
        title: labelHidden ? label : undefined,
        type: type,
        value: value,
      })}

      {errorMessage && <LabelError>{errorMessage}</LabelError>}
    </InputWrapper>
  );
}
