import styled, { css } from 'styled-components';

export type InputWrapperProps = {
  error?: boolean;
  width?: string;
  inline?: boolean;
};

export const InputWrapper = styled.div<InputWrapperProps>`
  display: inline-flex;
  align-items: ${({ inline }) => (inline ? 'center' : 'start')};
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
  ${({ inline }) =>
    inline &&
    css`
      label {
        margin-right: 6px;
      }
    `}
  width: ${({ width }) => (width ? width : '100%')};
  ${({ error, theme }) =>
    error &&
    css`
      label {
        color: ${theme.colorsNew.coralAlert.dark};
      }

      input {
        border-color: ${theme.colorsNew.coralAlert.regular};

        :-moz-ui-invalid {
          box-shadow: none;
        }
      }
    `}
`;
