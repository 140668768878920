import styled, { css } from 'styled-components';
import { typography } from '../ThemeProvider/ThemeProvider';
import { InputBaseProps } from './InputBase';

export const Label = styled.label<Pick<InputBaseProps, 'required' | 'inline'>>`
  ${typography('inputLabel')};
  display: inline-flex;
  flex-direction: row;
  font-weight: 500;
  line-height: 12px;
  ${({ inline }) => !inline && `margin-bottom: 6px;`}
  white-space: nowrap;

  &[for] {
    cursor: pointer;
  }

  &[hidden] {
    display: none;
  }

  ${({ required }) =>
    required
      ? css`
          ::after {
            content: '*';
            display: inline;
            width: min-content;
            margin-left: 1px;
            color: ${({ theme }) => theme.colorsNew.increase.regular};
          }
        `
      : null}
`;
