import styled from 'styled-components';

import { RadioButtonControl } from './RadioButtonControl';
import NaturalGrow from '../../animations/NaturalGrow';

export const RadioButtonFill = styled.div`
  background: ${({ theme }) => theme.colorsNew.increase.light30};
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  transform: scale(0);
  pointer-events: none;

  ${RadioButtonControl}:checked ~ & {
    transform: scale(1);
    animation: ${NaturalGrow} 200ms cubic-bezier(0, 0.85, 0.81, 1.15);
  }
`;
