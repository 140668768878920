import * as React from 'react';

export function LinkExternalIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M5.777 5.777v12.446h12.446V12L20 13.777v4.446A1.78 1.78 0 0118.221 20H5.777A1.78 1.78 0 014 18.221V5.777A1.78 1.78 0 015.777 4h4.446L12 5.777H5.777zm10.044 1.156L12.887 4h7.115v7.117L17.07 8.184l-6.219 6.223-1.257-1.258 6.228-6.216z"
        fillRule="evenodd"
      />
    </svg>
  );
}
