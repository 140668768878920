import React from 'react';

export function ArrowDropdownUp(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      height="5"
      viewBox="0 0 10 5"
      width="10"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10 5L5 0 0 5h10z" />
    </svg>
  );
}
