import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';

const Wrapper = styled.div`
  display: inline-block;
`;

const MenuWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export interface TriggerProps {
  'aria-expanded': boolean;
  'aria-haspopup': boolean;
}

export interface FloatingMenuController {
  toggleShowMenu: () => void;
  showMenu: boolean;
}

export interface FloatingMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  children: (args: FloatingMenuController) => React.ReactNode;
  renderTrigger: (props: TriggerProps, floatingMenu: FloatingMenuController) => React.ReactNode;
  closeOnClickOutside?: boolean;
  onmountOnHide?: boolean;
}

/**
 * A generic dropdown menu which is compliant with the
 * [WAI-ARIA guidelines](https://www.w3.org/TR/wai-aria-practices/#wai-aria-roles-states-and-properties-14)
 *
 * Please pass all props received in the renderTrigger render prop to the rendered trigger element.
 * */
export function FloatingMenu({
  children,
  renderTrigger,
  closeOnClickOutside = true,
  onmountOnHide = true,
  ...rest
}: FloatingMenuProps) {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    if (expanded && closeOnClickOutside) {
      setExpanded((expanded) => !expanded);
    }
  });
  const toggleShowMenu = () => setExpanded((e) => !e);
  return (
    <Wrapper ref={ref} {...rest}>
      {renderTrigger(
        {
          'aria-expanded': expanded,
          'aria-haspopup': true,
        },
        { toggleShowMenu, showMenu: expanded }
      )}
      <MenuWrapper>
        {onmountOnHide && expanded && children({ toggleShowMenu, showMenu: expanded })}
        {!onmountOnHide && (
          <div style={{ opacity: expanded ? 1 : 0, visibility: expanded ? 'visible' : 'hidden' }}>
            {children({ toggleShowMenu, showMenu: expanded })}
          </div>
        )}
      </MenuWrapper>
    </Wrapper>
  );
}
