import React from 'react';

export type ProductName = 'card' | 'match' | 'count' | 'pay' | 'platform';
export type Environment = 'production' | 'staging' | 'development';

type ConfigByEnvironment = {
  [environment in Environment]: Config;
};

const FALLBACK_CONFIG: Config = {
  authUrl: 'http://localhost:4444',
  blogUrl: 'https://increase.app/blog',
  platformUrl: 'http://localhost:3000',
  gatewayUrl: 'http://localhost:3000',
  supportUrl: 'https://soporte.increasecard.com/hc/es',
  landingUrl: 'https://increase.app',
  productName: 'platform',
  environment: 'development',
};

const baseConfigs: ConfigByEnvironment = {
  production: {
    authUrl: 'https://auth.increase.app',
    platformUrl: 'https://platform.increase.app',
    gatewayUrl: 'https://gateway.increase.app',
    blogUrl: 'https://increase.app/blog',
    supportUrl: 'https://soporte.increasecard.com/hc/es',
    landingUrl: 'https://increase.app',
    productName: 'platform',
    environment: 'production',
  },
  staging: {
    authUrl: 'https://auth.staging.increase.app',
    platformUrl: 'https://platform.staging.increase.app',
    gatewayUrl: 'https://gateway.staging.increase.app',
    blogUrl: 'https://increase.app/blog',
    supportUrl: 'https://soporte.increasecard.com/hc/es',
    landingUrl: 'https://staging.increase.app',
    productName: 'platform',
    environment: 'staging',
  },
  development: FALLBACK_CONFIG,
};

export interface Config {
  platformUrl: string;
  gatewayUrl: string;
  authUrl: string;
  supportUrl: string;
  blogUrl: string;
  landingUrl: string;
  productName: ProductName;
  environment: Environment;
}

export interface ConfigProviderProps {
  children: React.ReactNode;
  environment: Environment;
  productName: ProductName;
}

export const ConfigContext = React.createContext<Config>(
  baseConfigs.development
);

export function ConfigProvider({
  children,
  environment,
  productName,
}: ConfigProviderProps) {
  return (
    <ConfigContext.Provider
      value={{ ...baseConfigs[environment], productName }}
    >
      {children}
    </ConfigContext.Provider>
  );
}
