import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { InlineText } from '../typography/typography';
import { typography } from '../ThemeProvider/ThemeProvider';

export type ButtonVariant = 'primary' | 'outline' | 'invisible' | 'alert';

export type ButtonProps = {
  buttonType?: ButtonVariant;
  children: React.ReactNode;
  icon?: React.ReactChild;
  size?: 'small' | 'normal';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const variantStyles = (theme: DefaultTheme) => ({
  primary: {
    basicStyle: {
      background: theme.colorsNew.primaryAction.regular,
      border: theme.colorsNew.gray.subtleBorder,
      color: theme.colorsNew.gray.white,
    },
    stateStyles: {
      ':hover': {},
      ':not(:active):hover': {
        boxShadow: '1px 3px 4px rgba(15, 107, 255, 0.27), 1px 2px 2px rgba(15, 107, 255, 0.36)',
      },
      ':active': {
        background: theme.colorsNew.primaryAction.dark,
      },
    },
  },
  outline: {
    basicStyle: {
      background: 'transparent',
      border: `1px solid ${theme.colorsNew.primaryAction.regular}`,
      color: theme.colorsNew.primaryAction.regular,
    },
    stateStyles: {
      ':hover': {
        background: theme.colorsNew.primaryAction.regular,
        color: theme.colorsNew.gray.white,
      },
      ':active': {
        background: theme.colorsNew.primaryAction.dark,
        border: `1px solid ${theme.colorsNew.primaryAction.dark}`,
      },
    },
  },
  invisible: {
    basicStyle: {
      background: 'transparent',
      border: 'none',
      color: theme.colorsNew.primaryAction.regular,
    },
    stateStyles: {
      ':hover': {
        background: theme.colorsNew.primaryAction.alpha,
      },
      ':active': {
        background: theme.colorsNew.primaryAction.alpha,
        color: theme.colorsNew.primaryAction.dark,
      },
    },
  },
  alert: {
    basicStyle: {
      background: theme.colorsNew.coralAlert.regular,
      border: theme.colorsNew.gray.subtleBorder,
      color: theme.colorsNew.gray.white,
    },
    stateStyles: {
      ':hover': {},
      ':not(:active):hover': {
        boxShadow: '1px 3px 4px rgba(223, 19, 33, 0.27), 1px 2px 2px rgba(223, 19, 33, 0.36)',
      },
      ':active': {
        background: theme.colorsNew.coralAlert.dark,
      },
    },
  },
});

export const BaseButton = styled.button<ButtonProps>`
  ${({ size }) => (size === 'small' ? typography('cell') : typography('body'))};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: ${({ size }) => (size === 'small' ? '30px' : '40px')};
  border-radius: 4px;
  padding: 0
    ${({ icon, size }) => {
      if (icon) return '8px';
      return size === 'small' ? '8px' : '20px';
    }};
  width: max-content;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  transition: all 100ms ease-in-out;
  background-color: ${({ buttonType = 'primary', theme }) =>
    variantStyles(theme)[buttonType].basicStyle.background};
  border: ${({ buttonType = 'primary', theme }) =>
    variantStyles(theme)[buttonType].basicStyle.border};
  color: ${({ buttonType = 'primary', theme }) =>
    variantStyles(theme)[buttonType].basicStyle.color};
  ${InlineText} {
    color: ${({ buttonType = 'primary', theme }) =>
      variantStyles(theme)[buttonType].basicStyle.color};
  }
  :not(:disabled):hover ${InlineText} {
    color: ${({ buttonType = 'primary', theme }) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (variantStyles(theme)[buttonType].stateStyles as any)[':hover']['color']};
  }
  :not(:disabled):active ${InlineText} {
    color: ${({ buttonType = 'primary', theme }) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (variantStyles(theme)[buttonType].stateStyles as any)[':active']['color']};
  }
  :not(:disabled) {
    ${({ buttonType = 'primary', theme }) => variantStyles(theme)[buttonType].stateStyles}
  }
  :disabled {
    opacity: 0.36;
  }

  :focus {
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colorsNew.increase.light};
    outline: 0;

    &::-moz-focus-inner {
      border: 0;
    }
  }

  svg {
    fill: currentColor;
    margin-right: 4px;
    /* Same size in small and regular variants */
    height: 18px;
    width: 18px;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Button: React.FC<typeof BaseButton.arguments> = ({ children, icon, ...rest }) => (
  <BaseButton icon={icon} {...rest}>
    {icon} {children}
  </BaseButton>
);
