import React from 'react';
import styled from 'styled-components';

import { H3, Paragraph } from '../typography/typography';
import { CheckIcon } from '@increasecard/icons';

export const Stepper = styled.div``;

const StepperStepWrapper = styled.div`
  display: flex;
  height: 80px;

  &.stepper-pending,
  &.stepper-done {
    ${Paragraph}, ${H3} {
      color: ${({ theme }) => theme.colorsNew.gray.grayBold};
    }
  }
`;

export interface StepperStepProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  description?: string;
  title?: string;
  status: StepperStepStatus;
}

export type StepperStepStatus = 'done' | 'current' | 'pending';
type StepperStepIndicatorProps = Pick<StepperStepProps, 'status'>;

const StepperStepIndicatorWrapper = styled.div<StepperStepIndicatorProps>`
  width: 64px;
  display: flex;
  justify-content: center;
  padding: 4px 16px 0;
  border-left: 2px solid
    ${({ status, theme }) =>
      status === 'pending' ? theme.colorsNew.gray.grayBold : theme.colorsNew.increase.light30};
`;

const GreenCircle = styled.div`
  border-radius: 100%;
  background: ${({ theme }) => theme.colorsNew.increase.light30};
  height: 23px;
  width: 23px;
`;

const GrayCircumference = styled.div`
  border-radius: 100%;
  border: 2px solid #b1b1b1;
  height: 23px;
  width: 23px;
`;

function StepperStepIndicator({ status }: StepperStepIndicatorProps) {
  // eslint-disable-next-line default-case
  switch (status) {
    case 'current':
      return (
        <StepperStepIndicatorWrapper status={status}>
          <GreenCircle />
        </StepperStepIndicatorWrapper>
      );
    case 'pending':
      return (
        <StepperStepIndicatorWrapper status={status}>
          <GrayCircumference />
        </StepperStepIndicatorWrapper>
      );
    case 'done':
      return (
        <StepperStepIndicatorWrapper status={status}>
          <CheckIcon color="#3FBD9B" />
        </StepperStepIndicatorWrapper>
      );
  }
}

export const StepperStep = ({
  className,
  description,
  title,
  status,
  ...rest
}: StepperStepProps) => {
  return (
    <StepperStepWrapper className={`${className} stepper-${status}`} {...rest}>
      <StepperStepIndicator status={status} />
      <div>
        <Paragraph>{title}</Paragraph>
        <H3>{description}</H3>
      </div>
    </StepperStepWrapper>
  );
};
