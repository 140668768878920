import React from 'react';
import styled from 'styled-components';
import { typography } from '../ThemeProvider/ThemeProvider';

export type ModalContentProps = {
  align?: string;
  scrollable?: boolean;
  children?: React.ReactNode;
};

const Container = styled.div<ModalContentProps>`
  ${typography('body')}
  text-align: ${({ align }) => align};
  overflow-wrap: break-word;
  margin: 16px 0;
  padding: 0 16px;
  &.scrollable {
    overflow-y: auto;
    max-height: 60vh;
  }
  & p {
    margin: 0;
  }
`;

export const ModalContent = ({ align, children, scrollable }: ModalContentProps) => {
  return (
    <Container align={align} className={scrollable ? 'scrollable' : ''}>
      {children}
    </Container>
  );
};

export default ModalContent;
