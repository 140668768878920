import React from 'react';
import styled from 'styled-components';
import { typography } from '../ThemeProvider/ThemeProvider';

interface ToggleProps {
  checked: boolean;
  disabled?: boolean;
}

const SwitchWrapper = styled.div<Pick<SwitchProps, 'size'>>`
  ${({ size }) => (size === 'small' ? typography('cell') : typography('body'))}
  display: grid;
  width: max-content;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  column-gap: 8px;
`;

const SwitchControl = styled.input.attrs({ type: 'checkbox' })`
  height: 20px;
  width: 34px;
  opacity: 0;
  box-sizing: border-box;
  border: 0;
  margin: 0;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'unset')};
`;

const SwitchToggle = styled.div<ToggleProps>`
  height: 16px;
  width: 16px;
  position: absolute;
  top: 1px;
  left: 1px;
  background-color: ${({ checked, theme }) =>
    checked ? theme.colorsNew.gray.white : theme.colorsNew.gray.blackLight};
  border-radius: 100%;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;

  ${SwitchControl}:checked ~ & {
    transform: translateX(14px);
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

const SwitchControlWrapper = styled.div<ToggleProps>`
  position: relative;
  height: 20px;
  width: 34px;
  border: 1px solid
    ${({ checked, theme }) =>
      checked ? theme.colorsNew.increase.light30 : theme.colorsNew.gray.subtleBorder};
  background-color: ${({ checked, theme }) =>
    checked ? theme.colorsNew.increase.light30 : theme.colorsNew.gray.grayBold};
  border-radius: 10px;
  box-sizing: border-box;
  margin: 1px;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: ${({ disabled }) => (disabled ? 0.36 : 1)};

  transition: all 200ms;

  :focus-within {
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colorsNew.increase.light};
    outline: 0;
  }
`;

export interface SwitchProps
  extends ToggleProps,
    Omit<React.InputHTMLAttributes<HTMLInputElement>, 'checked' | 'type' | 'size'> {
  id: string;
  name?: string;
  label: string;
  labelHidden?: boolean;
  onChange?: () => void;
  size?: 'small' | 'normal';
}

function Switch({
  className,
  checked,
  id,
  name,
  label,
  labelHidden,
  size,
  onChange,
  disabled,
  ...rest
}: SwitchProps) {
  return (
    <SwitchWrapper size={size}>
      <SwitchControlWrapper checked={checked} className={className} disabled={disabled}>
        <SwitchControl
          aria-label={label}
          checked={checked}
          disabled={disabled}
          id={id}
          name={name}
          onChange={!disabled ? onChange : undefined}
          title={labelHidden ? label : undefined}
          {...rest}
        />
        <SwitchToggle checked={checked} />
      </SwitchControlWrapper>

      {!labelHidden && <label htmlFor={id}>{label}</label>}
    </SwitchWrapper>
  );
}

export { Switch };
