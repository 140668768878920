import React from 'react';
import styled from 'styled-components';

export type SpinnerProps = {
  size: number;
};

const SVGStyled = styled.svg<SpinnerProps>`
  & .path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    stroke: ${({ theme }) => theme.colorsNew.increase.light30};
    animation: dash 1.4s ease-in-out infinite;
  }
  width: ${({ size }) => (size ? size : 24)}px;
  height: ${({ size }) => (size ? size : 24)}px;
  animation: rotator 1.4s linear infinite;

  @keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }
    50% {
      stroke-dashoffset: ${187 / 4};
      transform: rotate(135deg);
    }
    100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
    }
  }
  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }
`;

export const Spinner = (props: SpinnerProps) => {
  return (
    <SVGStyled
      className="spinner"
      height="65px"
      viewBox="0 0 66 66"
      width="65px"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        className="path"
        cx="33"
        cy="33"
        fill="none"
        r="30"
        strokeLinecap="round"
        strokeWidth="6"
      />
    </SVGStyled>
  );
};
