import * as React from 'react';

export function FilterFilledIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      {...props}
    >
      <path d="M10 14L4 5V3h16v2l-6 9v6l-4 2v-8z" />
    </svg>
  );
}
