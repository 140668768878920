import React from 'react';

export const EyeSlashedIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg
    fill="currentColor"
    height="1em"
    viewBox="0 0 22 19"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11 4a5 5 0 015 5c0 .628-.123 1.249-.36 1.83l2.92 2.92A11.817 11.817 0 0021.99 9a11.827 11.827 0 00-11-7.5c-1.357 0-2.704.236-3.98.7l2.16 2.16A4.853 4.853 0 0111 4zM1 1.27l2.28 2.28.46.46A11.8 11.8 0 000 9a11.822 11.822 0 0015.38 6.66l.42.42L18.73 19 20 17.73 2.27 0 1 1.27zM6.53 6.8l1.55 1.55A2.821 2.821 0 008 9a3 3 0 003 3 2.82 2.82 0 00.65-.08l1.55 1.55A4.967 4.967 0 016.53 6.8zm4.31-.78l3.15 3.15.02-.16a3 3 0 00-3-3l-.17.01z" />
  </svg>
);
