import React from 'react';
import {
  ThemeProvider as SCThemeProvider,
  DefaultTheme,
  FlattenSimpleInterpolation,
  createGlobalStyle,
} from 'styled-components';
import { Theme } from '../../theme/Theme';
import { ColorName, ColorType } from '../../theme/colors';
import { TypographyName } from '../../theme/typography';
import { ProductName } from '../../theme/colorsNew';

export interface ThemeProviderProps {
  children: React.ReactNode;
  theme?: Partial<DefaultTheme>;
  productName?: ProductName;
}

const BaseFontStyles = createGlobalStyle`
  html {
    color: ${({ theme }) => theme.colorsNew.gray.black};
  }
`;

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, theme, productName }) => {
  const effectiveTheme = Object.assign({}, Theme(productName), theme);
  return (
    <React.Fragment>
      <SCThemeProvider theme={effectiveTheme}>
        <>
          <BaseFontStyles />
          {children}
        </>
      </SCThemeProvider>
    </React.Fragment>
  );
};

export type ThemeExtractor<K> = ({ theme }: { theme: DefaultTheme }) => K;

export function color(name: ColorName, variant: ColorType): ThemeExtractor<string> {
  return ({ theme }: { theme: DefaultTheme }) => theme.colors[name][variant];
}

export function typography(name: TypographyName): ThemeExtractor<FlattenSimpleInterpolation> {
  return ({ theme }: { theme: DefaultTheme }) => theme.typography[name];
}
