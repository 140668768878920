import styled from 'styled-components';
import { RadioButtonProps } from './RadioButton';

export const RadioButtonControlWrapper = styled.div<Pick<RadioButtonProps, 'checked'>>`
  height: 20px;
  width: 20px;
  position: relative;
  margin-right: 6px;
  border-radius: 100%;
  transition: box-shadow 200ms;

  :focus-within {
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colorsNew.increase.light};
    outline: 0;
  }

  &::before {
    content: '';
    border: 2px solid
      ${({ checked, theme }) =>
        checked ? theme.colorsNew.increase.light30 : theme.colorsNew.gray.grayMedium};
    border-radius: 100%;
    box-sizing: border-box;
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
`;
