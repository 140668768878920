import { colors } from './colors';
import { typography } from './typography';
import { breakpoints } from './breakpoints';
import { shadows } from './shadows';
import { ProductName, colorsNew } from './colorsNew';

export const Theme = (productName?: ProductName) => ({
  colors,
  colorsNew: colorsNew(productName),
  typography,
  breakpoints,
  shadows,
});
