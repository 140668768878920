import React, { useState, useEffect } from 'react';
import { Product } from '../types/Product';
import { usePlatformAPI } from '../hooks/usePlatformAPI';

export enum States {
  Idle = 'idle',
  Fetching = 'fetching',
  Success = 'success',
  Error = 'error',
}

const INITIAL_STATE: ProductsState = { state: States.Idle };

export type ProductsState =
  | {
      state: States.Success;
      products: Product[];
    }
  | {
      state: States.Fetching | States.Idle | States.Error;
    };

export const ProductsStateContext = React.createContext<ProductsState>(
  INITIAL_STATE
);

export interface ProductsStateProviderProps {
  children: React.ReactNode;
}

export function ProductsStateProvider({
  children,
}: ProductsStateProviderProps) {
  const [productsState, setProductsState] = useState<ProductsState>(
    INITIAL_STATE
  );
  const platformAPI = usePlatformAPI();
  useEffect(() => {
    setProductsState({ state: States.Fetching });
    platformAPI
      .getProducts()
      .then((products) => setProductsState({ state: States.Success, products }))
      .catch(() => setProductsState({ state: States.Error }));
  }, [platformAPI]);
  return (
    <ProductsStateContext.Provider value={productsState}>
      {children}
    </ProductsStateContext.Provider>
  );
}
