import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import NaturalGrow from '../../animations/NaturalGrow';
import { CheckIcon } from '@increasecard/icons';

const CheckboxInputWrapper = styled.div<Pick<CheckboxInputProps, 'checked'>>`
  display: inline-block;
  height: 18px;
  width: 18px;
  padding: 0;
  ${({ checked, theme }) =>
    checked
      ? css`
          background: ${theme.colorsNew.increase.light30};
        `
      : css`
          border: 2px solid ${theme.colorsNew.gray.grayMedium};
        `}
  border-radius: 2px;
  position: relative;
  box-sizing: border-box;
  margin-right: 12px;
  transition: box-shadow 200ms;

  :focus-within {
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colorsNew.increase.light};
    outline: 0;
  }
`;

export const CheckboxInputControl = styled.input.attrs({ type: 'checkbox' })`
  width: 18px;
  height: 18px;
  margin: 0;
  box-sizing: border-box;
  opacity: 0;
  position: absolute;
  top: -2px;
  left: -2px;
  border: 0;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
`;

export const CheckboxInputFill = styled(CheckIcon)`
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  path {
    color: ${({ theme }) => theme.colorsNew.gray.white};
    stroke: currentColor;
    stroke-width: 1px;
  }
  position: absolute;
  top: 1px;
  left: 1px;
  transform: scale(0);
  pointer-events: none;

  ${CheckboxInputControl}:checked ~ & {
    transform: scale(1);
    animation: ${NaturalGrow} 200ms cubic-bezier(0, 0.85, 0.81, 1.15);
  }
`;

interface CheckboxInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  id: string;
  indeterminate?: boolean;
}

export function CheckboxInput({
  id,
  indeterminate,
  checked,
  defaultChecked,
  onChange,
  ...rest
}: CheckboxInputProps) {
  const [realChecked, setRealChecked] = useState(checked || defaultChecked);
  return (
    <CheckboxInputWrapper checked={checked === undefined ? realChecked : checked}>
      <CheckboxInputControl
        checked={checked}
        defaultChecked={defaultChecked}
        id={id}
        onChange={(e) => {
          if (checked === undefined) setRealChecked(e.target.checked);
          onChange && onChange(e);
        }}
        {...rest}
      />
      <CheckboxInputFill />
    </CheckboxInputWrapper>
  );
}
