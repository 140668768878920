import React from 'react';
import styled from 'styled-components';
import ButtonDrawer from './ButtonDrawer';
import { SideBarLinks } from './SideBarLinks';
import { ProductName, SecondLevelLink, Product, SidebarLink } from './types';
import { Paragraph } from '../typography/typography';
import { typography, ThemeProvider } from '../ThemeProvider/ThemeProvider';
import { Grid } from '../Grid/Grid';
import { ProductName as NewProductName } from '../../theme/colorsNew';
import { ApplicationHeaderProps } from './ApplicationHeader';

type NavMenuProps = {
  visible?: boolean;
};

const NavMenu = styled(Grid)<NavMenuProps>`
  background: ${({ theme }) => theme.colorsNew.gray.white};
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100px;
  transition: 0.25s;
  transform: translateY(${({ visible }) => (visible ? '46px' : '-100%')});
  z-index: 1;
  box-shadow: ${({ visible }) => (visible ? 'rgba(0, 0, 0, 0.05) 0px 2px 4px' : 'none')};
  overflow-y: auto;
  display: flex;
  transform: translateY(${({ visible }) => (visible ? '56px' : '-100%')});
  height: auto;
  .container {
    height: 140px;
    padding: 0 26px;
  }

  a {
    color: inherit;
  }
`;

const IconWrapper = styled.div`
  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const SecondLevelActions = styled.div`
  position: relative;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .sl-container {
    display: inline-flex;
    align-items: center;
    width: 210px;
    padding: 10px;
    border-radius: 9px;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      color: ${({ theme }) => theme.colorsNew.primaryAction.regular};
      ${Paragraph} {
        color: ${({ theme }) => theme.colorsNew.primaryAction.regular};
      }
    }

    .sl-title {
      ${typography('body')};
      line-height: 1.25rem;
      margin: 0.25rem 0;
      text-align: center;
    }
    .sl-text {
      margin: 0;
      ${typography('cell')};
      text-align: center;
    }

    ${IconWrapper} {
      margin-right: 10px;
    }
  }
`;

const mapName: { [name in ProductName]: NewProductName } = {
  conciliation: 'match',
  card: 'card',
  pay: 'pay',
  calculadora: 'count',
};

interface MainNavMenuProps
  extends Pick<ApplicationHeaderProps, 'country' | 'products' | 'onProductAcquire'> {
  secondLevel: SecondLevelLink[];
  sidebar: SidebarLink[];
  visible: boolean;
}

export default function MainNavMenu({
  country,
  onProductAcquire,
  products,
  secondLevel,
  sidebar,
  visible,
}: MainNavMenuProps) {
  const handleProductAcquire = (product: Product) => (event: React.SyntheticEvent) => {
    event.preventDefault();
    onProductAcquire(product);
  };
  return (
    <NavMenu visible={visible}>
      <Grid className="container" columnGap={2}>
        {products.all
          .filter(({ countries }) => countries.includes(country))
          .map((product) => {
            const enabled = product.is_free || products.active[country].includes(product.name);
            return (
              <ThemeProvider key={product.name} productName={mapName[product.name as ProductName]}>
                <ButtonDrawer
                  className="span-desktop-2"
                  description={product.description}
                  displayName={product.display_name}
                  href={enabled ? product.uri : '#'}
                  onClick={!enabled ? handleProductAcquire(product) : undefined}
                  selected={products.current === product.name}
                />
              </ThemeProvider>
            );
          })}
        <SideBarLinks className="span-desktop-2" sidebar={sidebar} />
        <SecondLevelActions className="span-desktop-2">
          {secondLevel.map((item, i) => (
            <a className="sl-container" href={item.url} key={i} target="blank">
              <IconWrapper>{item.logo}</IconWrapper>
              <div>
                <Paragraph style={{ fontWeight: 'bold' }}>{item.name}</Paragraph>
                <p className="sl-text">{item.text}</p>
              </div>
            </a>
          ))}
        </SecondLevelActions>
      </Grid>
    </NavMenu>
  );
}
