import React from 'react';
import styled, { css } from 'styled-components';

import { ModalProps } from './Modal';

export type ModalFooterProps = {
  children?: React.ReactNode;
} & Pick<ModalProps, 'fullWidthActionButtons' | 'align'>;

const alignments = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};
const Container = styled.div<ModalFooterProps>`
  display: flex;
  justify-content: ${({ align }) => alignments[align || 'left']};
  ${({ fullWidthActionButtons }) => {
    return fullWidthActionButtons
      ? css`
          margin-top: 32px;

          & > * {
            flex-grow: 1;
          }

          & > *:first-child {
            border-radius: 0 0 0 3px;
            border-right: 0;
            padding: 0;
          }

          & > *:last-child {
            border-radius: 0 0 3px 0;
          }
        `
      : css`
          margin: 32px 16px 16px;

          & button:not(:last-child) {
            margin-right: 0.5rem;
          }
        `;
  }};
`;

export const ModalFooter = ({
  align,
  fullWidthActionButtons = false,
  children,
}: ModalFooterProps) => {
  return (
    <Container align={align} fullWidthActionButtons={fullWidthActionButtons}>
      {children}
    </Container>
  );
};

export default ModalFooter;
