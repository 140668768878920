import { Country } from '../types/Country';
import { useAuth } from './useAuth';
import { States } from '../contexts/AuthContext';

/**
 * Returns the current logged in user's country.
 */
export function useCountry(): Country | null {
  const auth = useAuth();
  if (auth.state !== States.Authenticated) return null;
  return auth.user.defaultCountry || 'argentina';
}
