import React, { ChangeEvent } from 'react';
import { InputBase, InputBaseProps } from '../Inputs';
import { FloatingMenu } from '../FloatingMenu/FloatingMenu';
import { Datepicker } from './Datepicker';
import parse from 'date-fns/parse';
import format from 'date-fns/format';
import styled, { css } from 'styled-components';
import { PopupArrow } from '../FloatingMenu/PopupArrow';
import { InputStyledBase } from '../Inputs/InputStyledBase';
import { EventIcon } from '@increasecard/icons';

export interface DateRangeInputProps extends InputBaseProps {
  onChange: (date: Date[]) => void;
  dateFormat?: string;
  value: Date[];
}

function parseDateRangeString(dateRangeString: string, format: string): Date[] {
  return dateRangeString.split(' - ').map((dateString) => parse(dateString, format, new Date()));
}

const IconWrapper = styled.div<Pick<DateRangeInputProps, 'size'>>`
  position: absolute;
  left: 4px;
  pointer-events: none;
  top: 0;
  margin: 4px 0;
  height: calc(100% - 8px);
  width: ${({ size }) => (size === 'small' ? '24px' : '34px')};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colorsNew.gray.blackLight};
  ${({ size }) =>
    size !== 'small' &&
    css`
      border-right: 1px solid ${({ theme }) => theme.colorsNew.gray.grayMedium};
    `}

  *:focus-within ~ & {
    border-right: 1px solid transparent;
    border-radius: 4px;
    color: ${({ theme }) => theme.colorsNew.increase.regular};
    background: ${({ theme }) => theme.colorsNew.gray.grayLight};
  }
`;

const Arrow = styled(PopupArrow)`
  position: absolute;
  top: -5px;
  left: 17px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export function DateRangeInput({
  value,
  onChange,
  dateFormat = 'dd/MM/yyyy',
  ...rest
}: DateRangeInputProps) {
  return (
    <FloatingMenu
      renderTrigger={(triggerProps, { toggleShowMenu }) => (
        <InputBase
          {...rest}
          {...triggerProps}
          inputStyle={{ paddingLeft: rest.size === 'small' ? '32px' : '48px' }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            onChange(parseDateRangeString(e.target.value, dateFormat));
          }}
          onClick={toggleShowMenu}
          value={value.map((date) => format(date, 'dd/MM/yyyy')).join(' - ')}
        >
          {(inputProps: typeof InputStyledBase.arguments) => (
            <Wrapper>
              <InputStyledBase {...inputProps} />
              <IconWrapper size={rest.size}>
                <EventIcon />
              </IconWrapper>
            </Wrapper>
          )}
        </InputBase>
      )}
    >
      {({ toggleShowMenu }) => (
        <div role="menu" style={{ position: 'absolute', top: '12px' }}>
          <Arrow />
          <Datepicker
            onChange={(dates: Date[]) => {
              toggleShowMenu();
              onChange(dates);
            }}
            selectRange
            value={value}
          />
        </div>
      )}
    </FloatingMenu>
  );
}
