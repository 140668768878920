import React from 'react';
import styled from 'styled-components';
import { H2 } from '../typography/typography';

const Container = styled.div<ModalHeaderProps>`
  padding: 0 16px;
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: ${({ align }) => align};
`;

export type ModalHeaderProps = {
  align?: string;
  children: React.ReactNode;
};

export const ModalHeader = ({ align, children }: ModalHeaderProps) => {
  return (
    <Container align={align}>
      <H2>{children}</H2>
    </Container>
  );
};

export default ModalHeader;
