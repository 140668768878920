import styled, { css } from 'styled-components';
import { typography } from '../ThemeProvider/ThemeProvider';

export interface DefaultProps {
  weight?: 'bold' | 'normal' | '400' | '700';
}

export interface SuperHeaderProps {
  weight?: 'normal' | 'bold' | '400' | '700' | '800';
}

export interface LabelProps {
  weight?: 'bold' | '500' | '700';
}

export const H1 = styled.h1<DefaultProps>`
  ${typography('H1')};
  margin: 0;
  font-weight: ${({ weight }) => weight || 'bold'};
`;

export const H2 = styled.h2<DefaultProps>`
  ${typography('H2')};
  margin: 0;
  font-weight: ${({ weight }) => weight || 'bold'};
`;

export const H3 = styled.h3<DefaultProps>`
  ${typography('H3')};
  margin: 0;
  font-weight: ${({ weight }) => weight || 'bold'};
`;

export const SuperHeader = styled.h1<SuperHeaderProps>`
  ${typography('superHeader')};
  margin: 0;
  font-weight: ${({ weight }) => weight || 'bold'};
`;

export interface Paragraph extends DefaultProps {
  weight?: 'normal' | 'bold' | '400' | '700';
  ellipsis?: boolean;
}

export const Paragraph = styled.p<Paragraph>`
  ${typography('body')};
  margin: 0;
  font-weight: ${({ weight }) => weight || 'normal'};
  ${({ ellipsis }) =>
    ellipsis &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
  & strong {
    font-weight: bold;
  }
`;

export interface InlineTexts extends DefaultProps {
  inline?: boolean;
}

export const CellText = styled.span<InlineTexts>`
  ${typography('cell')};
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
  font-weight: ${({ weight }) => weight || 'normal'};
  font-feature-settings: 'tnum' on;

  & strong {
    font-weight: bold;
  }
`;

export const InlineText = styled.span<InlineTexts>`
  ${typography('body')};
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
  font-weight: ${({ weight }) => weight || 'normal'};
  & strong {
    font-weight: bold;
  }
`;

export const Label = styled.label<LabelProps>`
  ${typography('label')};
  margin: 0;
  font-weight: ${({ weight }) => weight || '500'};
  & strong {
    font-weight: bold;
  }
`;

export const InputLabel = styled.label<LabelProps>`
  ${typography('inputLabel')};
  margin: 0;
  font-weight: ${({ weight }) => weight || '500'};
  & strong {
    font-weight: bold;
  }
`;

export const Caption = styled.label<LabelProps>`
  ${typography('caption')};
  margin: 0;
  font-weight: ${({ weight }) => weight || 'normal'};
  & strong {
    font-weight: bold;
  }
`;
