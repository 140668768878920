import React, { useState, useEffect } from 'react';
import { User } from '../models/User';
import { PlatformAPI } from '../services/PlatformAPI';
import { useConfig } from '../hooks/useConfig';

export enum States {
  Unknown = 'unknown',
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
}

const INITIAL_STATE: AuthState = { state: States.Unknown };

export interface AuthUnknown {
  state: States.Unknown;
}

export interface AuthAuthenticated {
  state: States.Authenticated;
  user: User;
}

export interface AuthAnonymous {
  state: States.Unauthenticated;
}

export type AuthState = AuthUnknown | AuthAnonymous | AuthAuthenticated;

export const AuthContext = React.createContext<AuthState>(INITIAL_STATE);

export interface AuthStateProviderProps {
  children: React.ReactNode;
}

export function AuthStateProvider({ children }: AuthStateProviderProps) {
  const [authState, setAuthState] = useState<AuthState>(INITIAL_STATE);
  const config = useConfig();
  useEffect(() => {
    PlatformAPI(`${config.gatewayUrl}/platform`)
      .getMe()
      .then((user) => setAuthState({ state: States.Authenticated, user }))
      .catch(() => setAuthState({ state: States.Unauthenticated }));
  }, [config]);
  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
}
