import React from 'react';
import { HelpIcon } from '@increasecard/icons';
import { Config } from '../contexts/ConfigContext';
import { defaultTranslationFunction } from '../services/i18n';

export type SecondLevelLink = {
  url: string;
  logo: JSX.Element;
  name: string;
  text: string;
};

const getSecondLevelLinks = (
  config: Config,
  t = defaultTranslationFunction('secondLevelLinks')
): SecondLevelLink[] => [
  {
    logo: <HelpIcon />,
    name: t('helpCenter'),
    text: t('helpCenterDescription'),
    url: config.supportUrl,
  },
];

export { getSecondLevelLinks };
