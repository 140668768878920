export type Country = typeof COUNTRIES[number];

export const COUNTRIES = [
  'argentina',
  'ecuador',
  'dominicana',
  'uruguay',
  'chile',
  'colombia',
  'paraguay',
] as const;
