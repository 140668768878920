import React from 'react';
import styled, { css } from 'styled-components';

import { RadioButtonFill } from './RadioButtonFill';
import { RadioButtonControl } from './RadioButtonControl';
import { RadioButtonControlWrapper } from './RadioButtonControlWrapper';
import { typography } from '../ThemeProvider/ThemeProvider';

export type RadioButtonProps = {
  label: string;
  name: string;
  inline?: boolean;
  size?: 'small' | 'normal';
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'size'>;

const RadioButtonWrapper = styled.label<Pick<RadioButtonProps, 'disabled' | 'inline' | 'size'>>`
  ${({ size }) => (size === 'small' ? typography('cell') : typography('body'))};
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  ${({ inline }) =>
    inline &&
    css`
      :not(:first-of-type) {
        margin-left: 16px;
      }
    `}

  ${({ disabled, theme }) => {
    if (disabled) {
      return `
            opacity: 0.2;
          `;
    }
    return `
            &:hover {
              color: ${theme.colorsNew.gray.black};
            }
          `;
  }};
`;

export const RadioButton = function ({
  checked,
  label,
  disabled,
  name,
  inline,
  size,
  ...other
}: RadioButtonProps) {
  return (
    <RadioButtonWrapper disabled={disabled} inline={inline} size={size}>
      <RadioButtonControlWrapper checked={checked}>
        <RadioButtonControl checked={checked} disabled={disabled} name={name} {...other} />
        <RadioButtonFill />
      </RadioButtonControlWrapper>
      {label}
    </RadioButtonWrapper>
  );
};
