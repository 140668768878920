import styled from 'styled-components';

export const PopupArrow = styled.div`
  height: 10px;
  width: 10px;
  transform: rotate(225deg);
  border: 1px solid ${({ theme }) => theme.colorsNew.gray.grayMedium};
  border-left: 0;
  border-top: 0;
  background: ${({ theme }) => theme.colorsNew.gray.white};
`;
