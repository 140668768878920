import React from 'react';

export function IncreaseLogo(props: React.SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      fill="#01A77B"
      height="25"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.52 24.984a5.544 5.544 0 01-5.536-5.537V5.52A5.543 5.543 0 015.52-.016h13.926a5.542 5.542 0 015.537 5.536v13.927a5.543 5.543 0 01-5.537 5.537H5.521zM1.347 5.52v13.927a4.18 4.18 0 004.175 4.176h13.926a4.18 4.18 0 004.174-4.176V5.52a4.179 4.179 0 00-4.174-4.174H5.521A4.18 4.18 0 001.346 5.52zm15.297 12.593L12.491 9.56l-4.08 8.554H5.733l5.453-11.258h2.595l5.454 11.258h-2.592z" />
    </svg>
  );
}
