export interface ColorsNew {
  increase: SemanticPaletteNew;
  product: SemanticPaletteNew;
  gray: GrayPalette;
  primaryAction: SecondaryPalette;
  coralAlert: SecondaryPalette;
  coral: CoralPalette;
  pay: SemanticPaletteNew;
  card: SemanticPaletteNew;
  match: SemanticPaletteNew;
  count: SemanticPaletteNew;
}

export interface CoralPalette {
  sLight: string;
  light: string;
  regular: string;
  medium: string;
  dark: string;
}

export interface SecondaryPalette {
  regular: string;
  dark: string;
  alpha: string;
}

export type ColorTypeNew =
  | 'light'
  | 'light70'
  | 'light50'
  | 'light30'
  | 'regular'
  | 'dark30'
  | 'dark50'
  | 'dark70'
  | 'dark'
  | 'accent';

export type SemanticPaletteNew = {
  [type in ColorTypeNew]: string;
};

export type GrayPaletteName =
  | 'white'
  | 'graySLight'
  | 'grayLight'
  | 'grayMedium'
  | 'grayBold'
  | 'blackSLight'
  | 'blackLight'
  | 'blackMedium'
  | 'black'
  | 'subtleBorder';

export type GrayPalette = {
  [type in GrayPaletteName]: string;
};

export type ProductName = 'card' | 'pay' | 'match' | 'count';

export type ProductColors = { [name in ProductName]: SemanticPaletteNew };

export const productColors: ProductColors = {
  card: {
    light: '#DAEFF7',
    light70: '#a4dff4',
    light50: '#80d3f2',
    light30: '#5cc9f0',
    regular: '#26b8ed',
    dark30: '#1b8dc2',
    dark50: '#1370a5',
    dark70: '#0b5388',
    dark: '#00275c',
    accent: '#00edff',
  },
  pay: {
    light: '#e6f8f8',
    light70: '#a1e4e3',
    light50: '#73d7d5',
    light30: '#45c9c8',
    regular: '#00b5b3',
    dark30: '#009499',
    dark50: '#007f87',
    dark70: '#006975',
    dark: '#00485b',
    accent: '#00f2de',
  },
  match: {
    light: '#e7d9fc',
    light70: '#c9bdfd',
    light50: '#b4aabfd',
    light30: '#a098fe',
    regular: '#827cff',
    dark30: '#7163ce',
    dark50: '#6552ad',
    dark70: '#5a418d',
    dark: '#49285c',
    accent: '#c8a1ff',
  },
  count: {
    light: '#ffe4d1',
    light70: '#fbd1a8',
    light50: '#f9c48c',
    light30: '#f6b770',
    regular: '#f2a447',
    dark30: '#e28940',
    dark50: '#d7783c',
    dark70: '#cc6638',
    dark: '#bc4b31',
    accent: '#ffda7c',
  },
};

const increaseColors = {
  light: '#d1f2e7',
  light70: '#93dbc7',
  light50: '#69ccb1',
  light30: '#3fbd9b',
  regular: '#01a77b',
  dark30: '#05926d',
  dark50: '#078464',
  dark70: '#09775a',
  dark: '#0d624c',
  accent: '#69f5a5',
};

export const colorsNew = (productName?: ProductName): ColorsNew => ({
  increase: increaseColors,
  product: productName ? productColors[productName] : increaseColors,
  gray: {
    white: '#ffffff',
    graySLight: '#fbfbfb',
    grayLight: '#f5f5f5',
    grayMedium: '#d8d8d8',
    grayBold: '#b1b1b1',
    blackSLight: '#6c7174',
    blackLight: '#42484d',
    blackMedium: '#2d3439',
    black: '#182026',
    subtleBorder: 'rgba(24, 32, 38, 0.16)',
  },
  coral: {
    sLight: '#ffd1cc',
    light: '#ff968c',
    regular: '#ff6f61',
    medium: '#e05a4d',
    dark: '#c83f32',
  },
  primaryAction: {
    regular: '#0f6bff',
    dark: '#07357f',
    alpha: 'rgba(15, 107, 255, 0.07)',
  },
  coralAlert: {
    regular: '#ff402e',
    dark: '#b80f00',
    alpha: 'rgba(255, 64, 46, 0.07)',
  },
  ...productColors,
});
