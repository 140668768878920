import React from 'react';

export const NoDataIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg
    fill="#B1B1B1"
    height="1em"
    viewBox="-9 -0.5 72 72"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M50.28 65.09H13.017a5.966 5.966 0 01-5.967-5.965V9.01c0-.843.18-1.676.525-2.444a5.965 5.965 0 00-5.8 5.961v50.114a5.967 5.967 0 005.966 5.966h37.266a5.967 5.967 0 005.442-3.521 2.327 2.327 0 01-.167.004z"
      opacity=".18"
    />
    <path d="M45.006 4.777H33.701a8.009 8.009 0 00-14.656 0h-11.3A7.774 7.774 0 000 12.53v50.114a7.75 7.75 0 007.745 7.724H45.01a7.75 7.75 0 007.745-7.724V12.53a7.775 7.775 0 00-7.749-7.753zm-24.69 3.517a1.747 1.747 0 001.719-1.368 4.416 4.416 0 014.338-3.388 4.415 4.415 0 014.339 3.385 1.749 1.749 0 001.717 1.374h3.439v1.571a4.191 4.191 0 01-4.154 4.231H21.036a4.193 4.193 0 01-4.16-4.225V8.297l3.44-.003zm28.917 54.35a4.227 4.227 0 01-4.227 4.207H7.741a4.228 4.228 0 01-4.228-4.207V12.53A4.254 4.254 0 017.74 8.294h5.62v1.571a7.712 7.712 0 007.677 7.749h10.671a7.713 7.713 0 007.678-7.749v-1.57h5.62a4.253 4.253 0 014.227 4.235l-.001 50.114z" />
    <path d="M17.635 40.85a2.785 2.785 0 100-5.57 2.785 2.785 0 000 5.57zM25.991 40.85a2.785 2.785 0 100-5.57 2.785 2.785 0 000 5.57zM34.347 40.85a2.785 2.785 0 100-5.57 2.785 2.785 0 000 5.57z" />
  </svg>
);
