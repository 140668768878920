import styled from 'styled-components';

export const RadioButtonControl = styled.input.attrs({ type: 'radio' })`
  margin: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
