import styled from 'styled-components';

export const LabelError = styled.span`
  color: ${({ theme }) => theme.colorsNew.coralAlert.regular};
  display: -webkit-box;
  font-size: 13px;
  line-height: 16px;
  margin-top: 3px;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
