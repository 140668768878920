import React from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { Link } from '../Link/Link';
import { ProfileLink } from './types';
import { typography } from '../ThemeProvider/ThemeProvider';
import { PersonIcon } from '@increasecard/icons';
import { ApplicationHeaderProps } from './ApplicationHeader';

const ProfileButton = styled.button`
  cursor: pointer;
  margin: 0px;
  padding: 0px;
  border: none;
  background: transparent;
  font-size: 13px;
  padding: 12px 6px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;

  > svg {
    margin-right: 4px;
  }

  &:focus,
  &:active,
  &:hover {
    outline: none;
    background: ${({ theme }) => theme.colorsNew.gray.grayLight};
  }
`;

const PopupMenu = styled.div`
  padding: 15px;
  z-index: 4;
  color: ${({ theme }) => theme.colorsNew.gray.black};
  font-size: 13px;
  line-height: 24px;
  .user {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    padding: 0 8px;
    margin-bottom: 3px;
  }
  a {
    color: ${({ theme }) => theme.colorsNew.gray.black};
    border-radius: 4px;
    padding: 8px;
    display: block;
    ${typography('cell')}
  }

  a:hover {
    text-decoration: none;
    background: ${({ theme }) => theme.colorsNew.gray.grayLight};
  }

  hr {
    background: transparent;
    margin: 0;
    margin-bottom: 8px;
    padding: 8px 0 0;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colorsNew.gray.grayMedium};
  }
`;

interface MenuProfileProps extends Pick<ApplicationHeaderProps, 'urls' | 'userName'> {
  profileLinks: ProfileLink[];
}

export default function MenuProfile({ urls, profileLinks, userName }: MenuProfileProps) {
  return (
    <Popup
      arrowStyle={{ boxShadow: '5px 5px 15px rgba(68, 73, 77, 0.2)' }}
      closeOnDocumentClick
      contentStyle={{
        zIndex: 4,
        padding: 0,
        boxShadow: '0px 0px 15px rgba(68, 73, 77, 0.2)',
        border: 'none',
      }}
      position="bottom right"
      trigger={() => (
        <ProfileButton>
          <PersonIcon height="20" width="20" />
          <span>Mi Cuenta</span>
        </ProfileButton>
      )}
    >
      <PopupMenu>
        <div className="user">{userName}</div>
        <Link href={`${urls.platform}/settings`}>Ajustes de cuenta</Link>
        {profileLinks.length > 0 && (
          <>
            <hr />
            {profileLinks.map((link) => (
              <Link href={link.url} key={link.url} target={link.blank ? '_blank' : '_self'}>
                {link.label}
              </Link>
            ))}
          </>
        )}
        <hr />
        <Link href={`${urls.auth}/logout`}>Cerrar Sesión</Link>
      </PopupMenu>
    </Popup>
  );
}
