import { States } from '../contexts/ProductsContext';
import { useProductsState } from './useProductsState';
import { usePlatformAPI } from '../hooks/usePlatformAPI';
import { useCallback } from 'react';
import { Product } from '@increase/typed-components/dist/components/ApplicationLayout/types';
/**
 * Returns the list of Increase's products,
 */

export const useOnAcquire = () => {
  const platformAPI = usePlatformAPI();

  const onAcquire = async (product: Product) => {
    if (product.acquire_behaviour === 'uri_acquire') {
      product.acquire_uri && (window.location.href = product.acquire_uri);
      return;
    }
    await platformAPI.createSubscription(product.name).then(() => {
      window.location.href = product.uri;
    });
  };

  return useCallback(onAcquire, [platformAPI]);
};

export function useProducts() {
  const productsState = useProductsState();
  if (productsState.state !== States.Success) return null;
  return productsState.products;
}
