import { UserSubscription } from '../types/UserSubscription';
import { UserAuthorization } from '../types/UserAuthorization';
import { UserAccount } from '../types/UserAccount';
import { Country, COUNTRIES } from '../types/Country';

const INITIAL_ACTIVE_PRODUCTS = COUNTRIES.reduce(
  (initialActiveProducts, country) => {
    initialActiveProducts[country] = [];
    return initialActiveProducts;
  },
  {} as Record<Country, string[]>
);
export class User {
  _activeProducts?: Record<Country, string[]>;
  _role?: string;

  constructor(
    readonly id: string,
    readonly firstName: string,
    readonly lastName: string,
    readonly email: string,
    readonly owner: boolean,
    readonly accountId: string,
    readonly defaultCountry: Country,
    readonly subscriptions: UserSubscription[],
    readonly authorizations: UserAuthorization[],
    readonly isMasquerading: boolean,
    readonly realUserEmail: string | null,
    readonly account: UserAccount,
    readonly createdAt: Date
  ) {
    this._activeProducts = undefined;
    this._role = undefined;
  }

  name() {
    return `${this.firstName} ${this.lastName}`;
  }

  activeProducts() {
    if (this._activeProducts) return this._activeProducts;

    this._activeProducts = this.subscriptions.reduce(
      (activeProducts, subscription) => {
        activeProducts[subscription.country].push(subscription.product.name);
        return activeProducts;
      },
      INITIAL_ACTIVE_PRODUCTS
    );
    return this._activeProducts;
  }

  displayName() {
    if (this.firstName) {
      return `${this.firstName} ${this.lastName}`;
    }
    return this.email;
  }

  productQuantity(product: string, country: string): number | undefined {
    return this.subscriptions.find(
      (sub) => sub.country === country && sub.product.name === product
    )?.terminals_count;
  }

  role() {
    if (this._role) return this._role;

    const platformAuthorization = this.authorizations.find(
      (authorization) =>
        authorization.product_name === 'platform' &&
        authorization.product_country === this.defaultCountry
    );
    this._role = platformAuthorization?.role;
    return this._role;
  }
}

export interface UserApiFields {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  owner: boolean;
  account_id: string;
  authorizations: UserAuthorization[];
  default_country: Country;
  subscriptions: UserSubscription[];
  real_user: Omit<UserApiFields, 'real_user'> | null;
  account: UserAccount;
  created_at: Date;
}

export const userMapper = (apiFields: UserApiFields): User =>
  new User(
    apiFields.id,
    apiFields.first_name,
    apiFields.last_name,
    apiFields.email,
    apiFields.owner,
    apiFields.account_id,
    apiFields.default_country,
    apiFields.subscriptions,
    apiFields.authorizations,
    apiFields.real_user !== null,
    apiFields.real_user && apiFields.real_user.email,
    apiFields.account,
    new Date(apiFields.created_at)
  );
