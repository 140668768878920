import React from 'react';
import styled from 'styled-components';
import { Link } from '../Link/Link';
import { SidebarLink } from './types';
import { typography } from '../ThemeProvider/ThemeProvider';

type SidebarLinksProps = {
  sidebar: SidebarLink[];
} & React.HTMLAttributes<HTMLDivElement>;

const SidebarStyled = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colorsNew.gray.grayMedium};
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .sidebar-link {
    ${typography('cell')}
    display: block;
    padding: 1.25rem;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    color: ${({ theme }) => theme.colorsNew.gray.black};
  }

  .sidebar-link:hover {
    color: ${({ theme }) => theme.colorsNew.primaryAction.regular};
    text-decoration: underline;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    position: relative;
    border-bottom: none;
    padding: 0 1.25rem 0 0;
    margin-bottom: 0;
    padding-left: 32px;
    width: 160px;
    .sidebar-link {
      padding: 0;
      margin-bottom: 6px;
      text-align: left;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &::before {
      position: absolute;
      content: '';
      background-color: ${({ theme }) => theme.colorsNew.gray.grayMedium};
      width: 1px;
      top: 15px;
      left: 0;
      bottom: 15px;
    }
  }
`;

export const SideBarLinks = ({ sidebar, ...rest }: SidebarLinksProps) => {
  return (
    <SidebarStyled {...rest}>
      <div>
        {sidebar.map((item, i) => (
          <Link
            className="sidebar-link"
            href={item.url}
            key={i}
            target={item.blank ? 'blank' : undefined}
          >
            {item.label}
          </Link>
        ))}
      </div>
    </SidebarStyled>
  );
};

export default SideBarLinks;
