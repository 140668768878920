import { Config } from '../contexts/ConfigContext';
import { defaultTranslationFunction } from '../services/i18n';

export type SidebarLink = { blank: boolean; url: string; label: string };

const getSidebarLinks = (
  config: Config,
  t = defaultTranslationFunction('sidebarLinks')
): SidebarLink[] => [
  {
    label: t('blog'),
    url: config.blogUrl,
    blank: true,
  },
];

export { getSidebarLinks };
