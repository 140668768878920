import { useContext } from 'react';
import { AuthContext, States } from '../contexts/AuthContext';
import { useConfig } from './useConfig';

/**
 * @returns {@linkcode AuthState} includes {@linkcode User} if state is
 * {@linkcode States.Authenticated}.
 */
export function useAuth({ requireAuth = false } = {}) {
  const config = useConfig();
  const auth = useContext(AuthContext);
  if (requireAuth && auth.state === States.Unauthenticated) {
    window.location.href = `${config.authUrl}/login?redirect=${window.location.href}`;
    return auth;
  }
  return auth;
}
