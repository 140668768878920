import React from 'react';

export const EllipsisIcon = (props: React.SVGAttributes<SVGSVGElement>) => (
  <svg
    fill="currentColor"
    height="1em"
    viewBox="0 -7 16 16"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2 0C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM8 0C6.9 0 6 .9 6 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
  </svg>
);
