import React from 'react';
import styled, { css } from 'styled-components';

import { CheckboxInput } from './CheckboxInput';
import { typography } from '../ThemeProvider/ThemeProvider';

export interface CheckboxProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'size'> {
  id: string;
  label: string;
  labelHidden?: boolean;
  size?: 'small' | 'normal';
}

const CheckboxWrapper = styled.div<Pick<CheckboxProps, 'size' | 'disabled'>>`
  ${({ size }) => (size === 'small' ? typography('cell') : typography('body'))}
  display: flex;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};

  ${({ disabled }) =>
    !disabled &&
    css`
      & label {
        cursor: pointer;
      }
    `};
`;

export function Checkbox({ id, label, labelHidden, disabled, size, ...other }: CheckboxProps) {
  return (
    <CheckboxWrapper disabled={disabled} size={size}>
      <CheckboxInput
        aria-label={label}
        disabled={disabled}
        id={id}
        title={labelHidden ? label : undefined}
        {...other}
      />
      {!labelHidden && <label htmlFor={id}>{label}</label>}
    </CheckboxWrapper>
  );
}
