import { useContext } from 'react';

import { ConfigContext } from '../contexts/ConfigContext';

/**
 * Returns configuration used by components in this package
 */
export function useConfig() {
  return useContext(ConfigContext);
}
