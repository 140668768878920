import warning from 'tiny-warning';

export interface Translations {
  [namespace: string]: { [key: string]: string | undefined };
}

const translations: Translations = {
  sidebarLinks: {
    moreProducts: 'Más productos',
    settings: 'Ajustes',
    blog: 'Blog',
    community: 'Comunidad',
  },
  secondLevelLinks: {
    helpCenter: 'Centro de ayuda',
    helpCenterDescription: 'Autogestión de dudas',
  },
  profileLinks: {
    support: 'Ayuda y Soporte',
    community: 'Comunidad Increase',
    blog: 'Novedades',
  },
};

export type TranslationFunction = (key: string) => string;

export function defaultTranslationFunction(
  namespace: keyof typeof translations
): TranslationFunction {
  return function(key: string): string {
    const translation = translations[namespace][key];
    warning(!translation, `Translation missing for key ${namespace}:${key}.`);
    return translation || key;
  };
}
