import React, { SyntheticEvent } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RemoveScrollBar } from 'react-remove-scroll-bar';
import { CSSTransition } from 'react-transition-group';
import ModalHeader from './ModalHeader';
import ModalContent from './ModalContent';
import ModalFooter from './ModalFooter';
import { Button } from '../Button/Button';
import { CloseIcon } from '@increasecard/icons';

export type ModalProps = {
  fullWidthActionButtons?: boolean;
  align?: 'left' | 'right' | 'center';
  okLabel?: string;
  cancelLabel?: string;
  children?: React.ReactNode;
  onOk: React.EventHandler<SyntheticEvent>;
  onCancel: React.EventHandler<SyntheticEvent>;
  onClose: React.EventHandler<SyntheticEvent>;
  shadeClosable?: boolean;
  closeOnEscape?: boolean;
  customFooter?: React.ReactNode;
  description?: string;
  headerText?: string;
  shade?: boolean;
  visible: boolean;
  width?: string;
};

const propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right', 'justify']),
  cancelLabel: PropTypes.string,
  closeOnEscape: PropTypes.bool,
  customFooter: PropTypes.array,
  description: PropTypes.string,
  fullWidthActionButtons: PropTypes.bool,
  headerText: PropTypes.string,
  okLabel: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func,
  shade: PropTypes.bool,
  shadeClosable: PropTypes.bool,
  width: PropTypes.number,
};

const defaultProps = {
  align: 'left',
  okLabel: 'Continuar',
  cancelLabel: 'Cancelar',
  closeOnEscape: true,
  fullWidthActionButtons: false,
  shadeClosable: true,
  shade: true,
  width: 600,
};

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
  overflow: hidden;
`;

const Shade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  .modal-enter & {
    opacity: 0;
  }
  .modal-enter-active & {
    opacity: 1;
    transition: opacity 200ms ease-out;
  }
  .modal-exit & {
    opacity: 1;
  }
  .modal-exit-active & {
    opacity: 0;
    transition: opacity 200ms ease-out;
  }
`;

const ModalBody = styled.div<{ width?: string }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${({ width }) => width}px;
  max-width: calc(100vw - 30px);
  max-height: calc(100vh - 30px);
  background-color: white;
  border-radius: 3px;
  color: ${({ theme }) => theme.colorsNew.gray.black};
  .modal-enter & {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
  .modal-enter-active & {
    opacity: 1;
    transform: translate(-50%, -50%);
    transition: opacity 200ms ease-out, transform 200ms ease-out;
  }
  .modal-exit & {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  .modal-exit-active & {
    opacity: 0;
    transform: translate(-50%, -100%);
    transition: opacity 200ms ease-out, transform 200ms ease-out;
  }
`;

const IconButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 9px;
  right: 9px;
  & svg {
    fill: ${({ theme }) => theme.colorsNew.gray.grayBold};
  }
`;

const CloseButton = ({ onClick }: { onClick: React.MouseEventHandler }) => {
  return (
    <IconButton as="a" onClick={onClick}>
      <CloseIcon height="24" width="24" />
    </IconButton>
  );
};

export function Modal({
  align,
  children,
  onClose,
  customFooter,
  description,
  fullWidthActionButtons,
  headerText,
  shade,
  visible,
  width,
  cancelLabel,
  onCancel,
  onOk,
  okLabel,
  closeOnEscape,
  shadeClosable,
}: ModalProps) {
  const getOrderedButtons = () => {
    const buttons = [
      <Button buttonType="invisible" key="cancelButton" onClick={onCancel}>
        {cancelLabel}
      </Button>,
      <Button key="okButton" onClick={onOk}>
        {okLabel}
      </Button>,
    ];
    return fullWidthActionButtons || align !== 'left' ? buttons : buttons.reverse();
  };

  const handleShadeClick = (e: React.SyntheticEvent) => {
    if (shadeClosable) {
      onClose(e);
    }
  };

  const handleEscKey = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape' && closeOnEscape) {
      onClose(e);
    }
  };

  return ReactDOM.createPortal(
    <CSSTransition classNames="modal" in={visible} mountOnEnter timeout={200} unmountOnExit>
      <ModalWrapper onClick={handleShadeClick} onKeyUp={handleEscKey}>
        {shade && <Shade />}
        <RemoveScrollBar />
        <ModalBody onClick={(e) => e.stopPropagation()} width={width}>
          <CloseButton onClick={onClose} />
          {children ? (
            children
          ) : (
            <React.Fragment>
              <ModalHeader align={align}>{headerText}</ModalHeader>
              <ModalContent align={align}>
                {description && <p>{description}</p>}
                {children}
              </ModalContent>
              <ModalFooter align={align} fullWidthActionButtons={fullWidthActionButtons}>
                {customFooter || getOrderedButtons()}
              </ModalFooter>
            </React.Fragment>
          )}
        </ModalBody>
      </ModalWrapper>
    </CSSTransition>,
    document.body
  );
}

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;
