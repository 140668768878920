import React from 'react';
import { TabDefinition } from './types';

import {
  VerticalNavigationElement,
  VerticalNavigation,
} from '../VerticalNavigation/VerticalNavigation';

type MobileContextualMenuProps = {
  tabs: TabDefinition[];
  onElementClicked: React.MouseEventHandler<HTMLButtonElement>;
};

export function MobileContextualMenu({ tabs, onElementClicked }: MobileContextualMenuProps) {
  return (
    <VerticalNavigation>
      {tabs.map((tab: TabDefinition) => {
        const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
          tab.onClick(e);
          onElementClicked(e);
        };
        return tab.as === 'button' ? (
          <VerticalNavigationElement as="button" key={tab.name} onClick={handleClick} {...tab}>
            {tab.name}
          </VerticalNavigationElement>
        ) : (
          <VerticalNavigationElement active={tab.active} key={tab.name} {...tab}>
            {tab.name}
          </VerticalNavigationElement>
        );
      })}
    </VerticalNavigation>
  );
}
