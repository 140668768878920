export type Breakpoints = {
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
};

export const breakpoints: Breakpoints = {
  sm: '320px',
  md: '640px',
  lg: '1280px',
  xl: '1440px',
  xxl: '1600px',
};
