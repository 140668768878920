import React from 'react';
import styled from 'styled-components';
import { H2, Button } from '@increase/typed-components';
import { MessageIcon } from '@increasecard/icons';
import { useConfig } from '../hooks/useConfig';

const Wrapper = styled.div`
  min-height: calc(100vh - 112px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .title {
    margin-bottom: 40px;
  }

  .description {
    margin-bottom: 32px;
  }
`;

const GreenCircle = styled.div`
  margin: 48px 0;
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colorsNew.gray.white};
  background: ${({ theme }) => theme.colorsNew.increase.regular};
  /* IncGreen innerShadow */

  box-shadow: inset 0px 3px 18px rgba(24, 32, 38, 0.36),
    inset 0px -3px 6px rgba(24, 32, 38, 0.18);
  border-radius: 50%;
`;

function capitalize(s: string) {
  return `${s.charAt(0).toUpperCase()}${s.slice(1)}`;
}

export function ErrorScreen() {
  const config = useConfig();
  const productDisplayName = capitalize(config.productName);
  return (
    <Wrapper>
      <GreenCircle>
        <MessageIcon height="96" width="96" />
      </GreenCircle>
      <H2 className="title">¡Ups! Algo ha pasado</H2>

      <H2 className="description" weight="normal">
        No te preocupes, ya fuimos notificados.
        <br />
        Continúa usando <strong>Increase {productDisplayName}</strong>.
      </H2>

      <Button as="a" href="/">
        Volver a {productDisplayName}
      </Button>
    </Wrapper>
  );
}
