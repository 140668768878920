import * as React from 'react';

export function ArrowLeftIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      {...props}
    >
      <path d="M13.59 18L15 16.59 10.42 12 15 7.41 13.59 6l-6 6 6 6z" />
    </svg>
  );
}
