import { UserApiFields } from '../models/User';
import { Product } from '../types/Product';
import { userMapper } from '../models/User';

export const PlatformAPI = (baseURL: string) => ({
  getMe: () => {
    return fetch(`${baseURL}/v1/users/me`, { credentials: 'include' })
      .then((response) => response.json())
      .then((value) => userMapper(value as UserApiFields));
  },
  getProducts: () => {
    return fetch(`${baseURL}/v1/products`, { credentials: 'include' })
      .then((response) => response.json())
      .then((value) => value.products as Product[]);
  },
  createSubscription: (productName: string) => {
    return fetch(`${baseURL}/v1/subscriptions`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ product_name: productName }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
});
