import { Config } from '../contexts/ConfigContext';
import { defaultTranslationFunction } from '../services/i18n';

type ProfileLink = {
  url: string;
  blank: boolean;
  label: string;
};

const getProfileLinks = (
  config: Config,
  t = defaultTranslationFunction('profileLinks')
): ProfileLink[] => [
  {
    label: t('support'),
    url: config.supportUrl,
    blank: true,
  },
  {
    label: t('blog'),
    url: config.blogUrl,
    blank: true,
  },
];

export { getProfileLinks };
