import React from 'react';
import styled from 'styled-components';
import {
  ApplicationLayout,
  Spinner,
  ApplicationLayoutProps,
} from '@increase/typed-components';
import { useAuth } from '../hooks/useAuth';
import { useProducts, useOnAcquire } from '../hooks/useProducts';
import { getProfileLinks } from '../data/profileLinks';
import { getSecondLevelLinks } from '../data/secondLevelLinks';
import { getSidebarLinks } from '../data/sidebarLinks';
import { useCountry } from '../hooks/useCountry';
import { useConfig } from '../hooks/useConfig';
import { States } from '../contexts/AuthContext';
import { Country } from '../types/Country';
import { ErrorBoundary } from './ErrorBoundary';

interface LayoutProps
  extends Omit<
    ApplicationLayoutProps,
    | 'userName'
    | 'activeProducts'
    | 'products'
    | 'onProductAcquire'
    | 'country'
    | 'links'
    | 'urls'
    | 'sidebar'
  > {
  children: React.ReactNode;
}

const SpinnerWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * Provides Increase's navigation bars, requires the list of tabs and the active product. Needs
 * {@linkcode BaseProvider} as an ancestor to work.
 */
export function Layout({ children, ...rest }: LayoutProps) {
  const auth = useAuth({ requireAuth: true });
  const config = useConfig();
  const products = useProducts();
  const country = useCountry() as Country;
  const onAcquire = useOnAcquire();

  if (auth.state !== States.Authenticated || products === null) {
    return (
      <SpinnerWrapper>
        <Spinner size={64} />
      </SpinnerWrapper>
    );
  }

  const user = auth.user;
  return (
    <ApplicationLayout
      {...rest}
      userName={user.name()}
      products={{
        all: products.filter(
          ({ acquire_behaviour }) => acquire_behaviour !== 'hidden'
        ),
        current: config.productName,
        active: user.activeProducts(),
      }}
      onProductAcquire={onAcquire}
      country={country}
      urls={{
        auth: config.authUrl,
        platform: config.platformUrl,
        platformApi: `${config.gatewayUrl}/platform`,
        landing: config.landingUrl,
      }}
      links={{
        profile: getProfileLinks(config),
        secondLevel: getSecondLevelLinks(config),
        sidebar: getSidebarLinks(config),
      }}
    >
      <ErrorBoundary>{children}</ErrorBoundary>
    </ApplicationLayout>
  );
}
