import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { typography } from '../ThemeProvider/ThemeProvider';
import { Label } from '../typography/typography';
import { NoDataIcon } from '@increasecard/icons';

export const TableWrapper = styled.div`
  background: white;
  border: 1px solid ${({ theme }) => theme.colorsNew.gray.grayMedium};
  border-radius: 9px;
  padding: 20px;
  flex: 1;
`;

export const TableHeader = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

export const TableRow = styled.tr<TableRowProps>`
  height: ${({ double }) => (double ? '58px' : '40px')};
  ${({ double, border }) =>
    (!double || border) &&
    css`
      border-bottom: 1px solid ${({ theme }) => theme.colorsNew.gray.grayMedium};
    `}
`;

const StyledTable = styled.table<Pick<TableProps, 'zebra'>>`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  font-size: 13px;
  line-height: 16px;
  ${({ zebra }) =>
    zebra &&
    css`
      tr:nth-child(even) {
        background: ${({ theme }) => theme.colorsNew.gray.grayLight};
      }

      ${TableRow} {
        border-bottom: none;
      }
    `}
`;

export const THead = styled.thead`
  border-bottom: 3px solid ${({ theme }) => theme.colorsNew.gray.black};
`;

export type ThProps = TdProps;

export const Th = styled.th<ThProps>`
  ${typography('label')};
  text-align: left;
  font-weight: 500;
  height: 42px;
  vertical-align: middle;
  padding: 8px;
  text-transform: uppercase;
  ${({ numeric, rightAligned }) =>
    (numeric || rightAligned) &&
    css`
      text-align: right;
    `}
`;

export type TableRowProps = {
  double?: boolean;
  border?: boolean;
};

export type TdProps =
  | {
      /** @deprecated use rightAligned instead */
      numeric?: boolean;
      rightAligned?: undefined;
    }
  | {
      rightAligned?: boolean;
      /** @deprecated use rightAligned instead */
      numeric?: undefined;
    };

export const Td = styled.td<TdProps>`
  vertical-align: middle;
  padding: 0 8px;
  ${({ numeric, rightAligned }) =>
    (numeric || rightAligned) &&
    css`
      text-align: right;
    `}
`;

const NoDataLabel = styled(Label)`
  color: ${({ theme }) => theme.colorsNew.gray.grayBold};
`;

const NoData = () => (
  <div
    style={{
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      margin: '16px 0 0',
    }}
  >
    <NoDataIcon height="96" width="96" />
    <NoDataLabel style={{ marginTop: '8px' }}>No hay datos</NoDataLabel>
  </div>
);

export const Table = ({ children, zebra, hasData, ...rest }: TableProps) => {
  return (
    <React.Fragment>
      <StyledTable {...rest} zebra={zebra}>
        {children}
      </StyledTable>
      {hasData === false && <NoData />}
    </React.Fragment>
  );
};

Table.propTypes = {
  children: PropTypes.node,
};

export type TableProps = {
  children: React.ReactNode;
  zebra?: boolean;
  hasData?: boolean;
} & React.TableHTMLAttributes<HTMLTableElement>;
