import React, { useEffect, useState } from 'react';
import mixpanel, * as Mixpanel from 'mixpanel-browser';
import { useConfig } from '../hooks/useConfig';
import { useAuth } from '../hooks/useAuth';
import { States } from './AuthContext';

export type EventTrackingProviderProps = EventTrackingSettings & {
  children: React.ReactNode;
};

export type EventTrackingSettings = {
  debug?: boolean;
} & (
  | {
      enabled: true;
      mixpanelToken: string;
    }
  | {
      enabled?: false;
      mixpanelToken?: string;
    }
);

export interface EventTracker {
  init(token?: string, config?: Partial<Mixpanel.Config>, name?: string): void;
  register(baseMetadata?: Mixpanel.Dict): void;
  track(event_name: string, properties?: Mixpanel.Dict): void;
  identify(id: string): void;
}
export type ExportedEventTracker = Pick<EventTracker, 'track'>;

class FakeTracker implements EventTracker {
  private baseMetadata?: Mixpanel.Dict;
  private id?: string;

  constructor(private debug?: boolean) {}

  init(_token?: string, _config?: Partial<Mixpanel.Config>, _name?: string) {}
  identify(id: string) {
    this.id = id;
  }
  register(baseMetadata?: Mixpanel.Dict) {
    this.baseMetadata = baseMetadata;
  }
  track(event_name: string, properties?: Mixpanel.Dict) {
    if (this.debug !== true) return;
    console.debug('Would track: ', event_name, {
      ...properties,
      ...this.baseMetadata,
      id: this.id,
    });
  }
}

export const EventTrackingContext = React.createContext<EventTracker>(mixpanel);

export function EventTrackingProvider({
  children,
  ...settings
}: EventTrackingProviderProps) {
  const tracker: EventTracker = settings.enabled
    ? mixpanel
    : new FakeTracker(settings.debug);
  const [trackerConfigured, setTrackerConfigured] = useState(false);
  const authState = useAuth();
  const config = useConfig();

  useEffect(() => {
    tracker.init(
      settings.enabled === true ? settings.mixpanelToken : undefined,
      { debug: settings.debug }
    );
  }, [tracker, settings]);

  useEffect(() => {
    if (authState.state === States.Unknown) {
      return;
    }
    if (authState.state === States.Unauthenticated) {
      setTrackerConfigured(true);
      return;
    }
    const user = authState.user;
    tracker.identify(user.email);
    tracker.register({
      account_id: user.accountId,
      product: config.productName,
      masquerading: user.isMasquerading,
      $email: user.email,
      'Impersonator Email': user.realUserEmail,
      subscription_id: user.subscriptions.find(
        (s) => s.product.name === config.productName
      )?.id,
    });
    setTrackerConfigured(true);
  }, [authState, config.productName, tracker]);

  // We block until the tracker is configured
  // This avoids a race condition that causes anonymous events to be sent
  if (authState.state === States.Unknown || !trackerConfigured) return null;

  return (
    <EventTrackingContext.Provider value={tracker}>
      {children}
    </EventTrackingContext.Provider>
  );
}
