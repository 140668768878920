export type ColorName =
  | 'brandWhite'
  | 'brandBlack'
  | 'brandGreen'
  | 'brandBlue'
  | 'sky'
  | 'nightSky'
  | 'blueGum'
  | 'eter'
  | 'liquen'
  | 'coral'
  | 'sol'
  | 'alerta';
export type Colors = {
  [name in ColorName]: SemanticPalette;
};

export type ColorType = 'light' | 'regular' | 'medium' | 'bold';

export type SemanticPalette = {
  [type in ColorType]: string;
};

export const colors: Colors = {
  brandWhite: {
    regular: '#ffffff',
    light: '#F5F5F5',
    medium: '#D8D8D8',
    bold: '#B1B1B1',
  },

  brandBlack: {
    regular: '#182026',
    light: '#44494D',
    medium: '#14191C',
    bold: '#000000',
  },

  brandGreen: {
    regular: '#01A77B',
    light: '#D1F2E7',
    medium: '#01A77B',
    bold: '#0D624C',
  },

  brandBlue: {
    regular: '#243568',
    light: '#667296',
    medium: '#404B6F',
    bold: '#192448',
  },

  sky: {
    regular: '#0F6BFF',
    light: '#87B5FF',
    medium: '#4775BF',
    bold: '#07357F',
  },

  nightSky: {
    regular: '#2B62BA',
    light: '#648CCC',
    medium: '#245199',
    bold: '#183666',
  },

  blueGum: {
    regular: '#239DCE',
    light: '#73C0DF',
    medium: '#208FBC',
    bold: '#176484',
  },

  eter: {
    regular: '#D0F6DD',
    light: '#DCF8E6',
    medium: '#BEE0C9',
    bold: '#ABCAB5',
  },

  liquen: {
    regular: '#43B7B7',
    light: '#76CACA',
    medium: '#3DA7A7',
    bold: '#318686',
  },

  coral: {
    regular: '#FF6F61',
    light: '#FF968C',
    medium: '#E86559',
    bold: '#BA5147',
  },

  sol: {
    regular: '#FFAC30',
    light: '#F9B96F',
    medium: '#D29249',
    bold: '#AB6B22',
  },

  alerta: {
    regular: '#E71321',
    light: '#F38990',
    medium: '#B34950',
    bold: '#730910',
  },
};
