import React, { useState, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { States } from './AuthContext';
import Rollbar from 'rollbar';
import { useConfig } from '../hooks/useConfig';

export type ErrorTrackingProviderProps = ErrorTrackingSettings & {
  children: React.ReactNode;
};

export type ErrorTrackingSettings =
  | { enabled?: false; rollbarToken?: string }
  | {
      enabled: true;
      /** A rollbar POST_CLIENT_ITEM_TOKEN */ rollbarToken: string;
    };

export const ErrorTrackingContext = React.createContext<Rollbar | null>(null);

export function ErrorTrackingProvider({
  children,
  ...settings
}: ErrorTrackingProviderProps) {
  const authState = useAuth();
  const config = useConfig();
  const [tracker, setTracker] = useState<Rollbar | null>(null);
  const [trackerConfigured, setTrackerConfigured] = useState(false);

  const trackingEnabled =
    settings.enabled === true && process.env.NODE_ENV === 'production';

  useEffect(() => {
    if (!trackingEnabled || tracker !== null) return;
    setTracker(
      new Rollbar({
        accessToken: settings.rollbarToken,
        environment: config.environment,
        captureUncaught: true,
        captureUnhandledRejections: true,
      })
    );
  }, [settings, tracker, trackingEnabled, config.environment]);

  useEffect(() => {
    if (authState.state === States.Unauthenticated) {
      setTrackerConfigured(true);
      return;
    }
    if (authState.state === States.Unknown || tracker === null) {
      // Wait of auth check
      return;
    }
    const user = authState.user;
    tracker.configure({
      payload: {
        person: {
          id: user.email,
          username: user.email,
          email: user.email,
        },
      },
    });
    setTrackerConfigured(true);
  }, [authState, tracker, trackingEnabled]);

  if (!trackingEnabled) {
    return (
      <ErrorTrackingContext.Provider value={null}>
        {children}
      </ErrorTrackingContext.Provider>
    );
  }

  // We block until we know if there is a user to identify or not
  // This avoids a race condition that causes anonymous Errors to be sent
  if (
    authState.state === States.Unknown ||
    tracker === null ||
    !trackerConfigured
  )
    return null;

  return (
    <ErrorTrackingContext.Provider value={tracker}>
      {children}
    </ErrorTrackingContext.Provider>
  );
}
