import React from 'react';
import { ConfigProvider, Environment, ProductName } from './ConfigContext';
import { AuthStateProvider } from './AuthContext';
import { ProductsStateProvider } from './ProductsContext';
import {
  EventTrackingProvider,
  EventTrackingSettings,
} from './EventTrackingContext';
import {
  ErrorTrackingSettings,
  ErrorTrackingProvider,
} from './ErrorTrackingContext';

export interface BaseProviderProps {
  children: React.ReactNode;
  settings: BaseProviderSettings;
}

export interface BaseProviderSettings {
  environment: Environment;
  productName: ProductName;
  eventTracking?: EventTrackingSettings;
  errorTracking?: ErrorTrackingSettings;
}

/**
 * Insert this component as high as possible in the component tree, it is required by most
 * components in this package.
 */
export function BaseProvider({ children, settings }: BaseProviderProps) {
  return (
    <ConfigProvider
      environment={settings.environment}
      productName={settings.productName}
    >
      <AuthStateProvider>
        <ErrorTrackingProvider {...settings.errorTracking}>
          <EventTrackingProvider {...settings.eventTracking}>
            <ProductsStateProvider>{children}</ProductsStateProvider>
          </EventTrackingProvider>
        </ErrorTrackingProvider>
      </AuthStateProvider>
    </ConfigProvider>
  );
}
