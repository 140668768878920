import * as React from 'react';

export function ArrowRightIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      height="1em"
      viewBox="0 0 24 24"
      width="1em"
      {...props}
    >
      <path d="M9.41 6L8 7.41 12.58 12 8 16.59 9.41 18l6-6-6-6z" />
    </svg>
  );
}
